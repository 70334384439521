// MessageList.jsx
import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    Avatar,
    AvatarGroup,
    Chip,
    Link,
} from '@mui/material'
import MessageInput from './MessageInput'
import ProfileIcon from './ProfileIcon'
import InternalLink from './InternalLink'
import { getBackendUserUrl } from '../../utils'
import { useUserAndConfig } from '../../hooks/useUserAndConfig'

// Helper function to safely parse JSON string or handle object
const parseReferenceTag = (tag) => {
    try {
        // If it's already an array, return it
        if (Array.isArray(tag)) {
            return tag
        }

        // If it's an object but not an array, wrap it in array
        if (typeof tag === 'object' && tag !== null) {
            return [tag]
        }

        // If it's a string, parse it and wrap in array if needed
        const parsed = JSON.parse(tag)
        return Array.isArray(parsed) ? parsed : [parsed]
    } catch (error) {
        console.warn('Error parsing referenceTag:', error)
        return [] // Return empty array as fallback
    }
}

function UtteranceList({ conversation = {}, onTagClick, sender, receiver }) {
    const [messages, setMessages] = useState([])
    const [tags, setTags] = useState([])
    console.log('UtteranceList Conversation', { conversation })
    console.log('UtteranceList Sender', { sender })
    console.log('UtteranceList Receiver', { receiver })

    const sendMessageAndNotify = async (
        messageSummary,
        eventLink,
        referenceTag
    ) => {
        console.log('sendMessageAndNotify params', {
            receiver,
            sender,
            messageSummary,
            eventLink,
            referenceTag,
        })
        try {
            const senderId = sender?.userId 
            const receiverId = receiver?.userId
            const eventId = conversation.eventId

            if (!senderId || !receiverId) {
                console.error('Missing sender or receiver ID')
                return
            }

            const apiUrl =
                getBackendUserUrl() +
                '/send-notification/new_message_db_and_notify'
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    senderId: senderId,
                    receiverId: receiverId,
                    eventId: eventId,
                    content: messageSummary,
                    event_link: eventLink,
                    referenceTag: referenceTag,
                }),
            })
            if (!response.ok) {
                const errorData = await response.json()
                console.error('Server error:', errorData)
                throw new Error(
                    `Server responded with ${response.status}: ${JSON.stringify(errorData)}`
                )
            }
            const data = await response.json()
            console.log('Email notification response:', data)
        } catch (error) {
            console.error('Error sending email notification:', error)
        }
    }

    useEffect(() => {
        if (conversation?.messages) {
            setMessages(conversation.messages)
        } else {
            setMessages([])
        }
        console.log('UtteranceList', { conversation })
    }, [conversation])

    const handleSendMessage = async (content) => {
        try {
            const newMessage = {
                senderId: sender?.userId,
                receiverId: receiver?.userId,
                eventId: conversation.eventId,
                content: content.message,
                // Send the tag object directly
                referenceTag: content.referenceTag,
                created_at: new Date().toISOString(),
                eventLink: content.eventLink || 'https://eventrent.tech',
            }

            // Optimistically add message to UI
            setMessages((prev) => [...prev, newMessage])

            // Send to backend
            await sendMessageAndNotify(
                content.message,
                window.location.href,
                content.referenceTag // Send single tag object
            )
        } catch (error) {
            console.error('Error sending message:', error)
            // Optionally remove the optimistically added message if there was an error
            setMessages((prev) => prev.slice(0, -1))
        }
    }

    // Helper function to format date safely
    const formatMessageTime = (dateString) => {
        try {
            const date = new Date(dateString)
            if (isNaN(date.getTime())) {
                return '' // Return empty string for invalid dates
            }
            return date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            })
        } catch (error) {
            console.error('Error formatting date:', error)
            return ''
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
            }}
        >
            {/* Subheader with user details */}
            <Paper
                elevation={0}
                sx={{
                    px: 2,
                    py: 1.5,
                    borderRadius: 0,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'background.paper',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography variant="subtitle1">
                            {receiver?.user?.firstName || 'New Conversation'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {receiver?.user?.email || ''}
                        </Typography>
                    </Box>
                    <AvatarGroup max={2}>
                        {sender && (
                            <Avatar alt={sender.firstName}>
                                {sender.user?.firstName?.charAt(0)}
                            </Avatar>
                        )}
                        {receiver && (
                            <Avatar alt={receiver.firstName}>
                                {receiver.user?.firstName?.charAt(0)}
                            </Avatar>
                        )}
                    </AvatarGroup>
                </Box>
            </Paper>

            {/* Messages section */}
            <Box
                sx={{
                    flex: '1 1 auto',
                    overflowY: 'auto',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    minHeight: 0,
                }}
            >
                <List>
                    {messages.map((msg, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                justifyContent:
                                    msg.senderId === sender?.userId
                                        ? 'flex-end'
                                        : 'flex-start',
                                alignItems: 'flex-start',
                            }}
                        >
                            {/* Avatar for receiver's messages */}
                            {msg.senderId !== sender?.userId && (
                                <Avatar
                                    sx={{ 
                                        width: 32, 
                                        height: 32, 
                                        mr: 1,
                                        bgcolor: 'primary.main'
                                    }}
                                >
                                    {receiver?.user?.firstName?.charAt(0) || '?'}
                                </Avatar>
                            )}
                            
                            <ListItemText
                                primary={
                                    <Paper
                                        sx={{
                                            p: 1,
                                            backgroundColor:
                                                msg.senderId === sender?.userId
                                                    ? 'primary.main'
                                                    : 'grey.200',
                                            color:
                                                msg.senderId === sender?.userId
                                                    ? 'white'
                                                    : 'text.primary',
                                            borderRadius:
                                                msg.senderId === sender?.userId
                                                    ? '20px 20px 0 20px'
                                                    : '20px 20px 20px 0',
                                        }}
                                    >
                                        {msg.content}
                                        {msg.referenceTag && (
                                            <Box
                                                sx={{
                                                    mt: 1,
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 0.5,
                                                }}
                                            >
                                                {(() => {
                                                    const tags =
                                                        parseReferenceTag(
                                                            msg.referenceTag
                                                        )
                                                    console.log(
                                                        'Parsed tags:',
                                                        tags
                                                    ) // Debug log
                                                    return tags.map(
                                                        (tag, index) => (
                                                            <InternalLink
                                                                key={index}
                                                                label={
                                                                    tag.label
                                                                }
                                                                onClick={() =>
                                                                    onTagClick(
                                                                        tag.label
                                                                    )
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        msg.senderId ===
                                                                        sender?.userId
                                                                            ? 'rgba(255,255,255,0.2)'
                                                                            : 'rgba(0,0,0,0.1)',
                                                                    color:
                                                                        msg.senderId ===
                                                                        sender?.userId
                                                                            ? 'white'
                                                                            : 'text.primary',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        backgroundColor:
                                                                            msg.senderId ===
                                                                            sender?.userId
                                                                                ? 'rgba(255,255,255,0.3)'
                                                                                : 'rgba(0,0,0,0.2)',
                                                                    },
                                                                }}
                                                            />
                                                        )
                                                    )
                                                })()}
                                            </Box>
                                        )}
                                    </Paper>
                                }
                                secondary={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 0.5,
                                            display: 'block',
                                            textAlign:
                                                msg.senderId === sender?.userId
                                                    ? 'right'
                                                    : 'left',
                                        }}
                                    >
                                        {formatMessageTime(msg.created_at)}
                                    </Typography>
                                }
                                sx={{ maxWidth: '70%' }}
                            />

                            {/* Avatar for sender's messages */}
                            {msg.senderId === sender?.userId && (
                                <Avatar
                                    sx={{ 
                                        width: 32, 
                                        height: 32, 
                                        ml: 1,
                                        bgcolor: 'secondary.main'
                                    }}
                                >
                                    {sender?.user?.firstName?.charAt(0) || '?'}
                                </Avatar>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>

            <MessageInput
                onSendMessage={handleSendMessage}
            />
        </Box>
    )
}

export default UtteranceList
