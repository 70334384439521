import React from 'react'
import { toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'
import { getBackendUserUrl, getGoogleClientId } from 'utils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const handleMailjetSubmit = async (email) => {
    try {
        const apiUrl = getBackendUserUrl() + '/mailjet/add_contact'
        const response = await axios.post(
            apiUrl,
            { email: email },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (response.status === 200) {
            console.log('Submitted email address to mailjet')
        } else {
            console.error(
                'Error adding email to waitlist: ',
                response.statusText
            )
            alert('An error occurred. Please try again later.')
        }
    } catch (error) {
        console.error(
            'Error adding email to waitlist: ',
            error.response?.data || error.message
        )
        alert('An error occurred. Please try again later.')
    }
}

const GoogleSignIn = ({ onUserLogin }) => {
    const { login } = useUser()
    const history = useHistory()

    const clientId = getGoogleClientId()

    if (!clientId) {
        console.error('Google Client ID is not configured')
        return <div>Authentication configuration error</div>
    }

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            console.log('Google login response received')

            if (!credentialResponse.credential) {
                console.error('No credential received from Google')
                toast.error('Google sign-in failed. Please try again.')
                return
            }

            const apiUrl = getBackendUserUrl() + '/user/auth/google'
            console.log('Sending request to:', apiUrl)

            const response = await axios.post(
                apiUrl,
                {
                    token: credentialResponse.credential,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            console.log('Server response received:', response.status)

            const { token, user } = response.data

            login(user, token)

            if (onUserLogin) {
                onUserLogin(user)
            }

            if (
                user.role &&
                (user.role.includes('user') || user.role.includes('admin'))
            ) {
                if (onUserLogin) {
                    onUserLogin({
                        success: true,
                        message: 'Welcome to Happenings Beta!',
                    })
                }
                history.push('/myevents')
            } else {
                if (onUserLogin) {
                    onUserLogin({
                        success: true,
                        message:
                            "Thank you for requesting beta access. We'll notify you once approved.",
                    })
                }
                await handleMailjetSubmit(user.email)
                history.push('/')
            }
        } catch (error) {
            console.error('Error during Google Sign-In:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
            })

            if (error.response?.status === 500) {
                toast.error('Server error. Please try again later.')
            } else if (error.response?.status === 401) {
                toast.error('Authentication failed. Please try again.')
            } else {
                toast.error(
                    `Login failed: ${error.response?.data?.detail || 'Please try again'}`
                )
            }
        }
    }

    return (
        <>
            <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={(error) => {
                    console.error('Google Login Error:', error)
                    toast.error('Login failed. Please try again.')
                }}
                useOneTap={false}
                theme="outline"
                size="large"
                text="signin_with"
                shape="rectangular"
            />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default GoogleSignIn
