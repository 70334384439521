import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const PackageInfoModal = ({ open, onClose, packageOptions, onSelectPackage }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    padding: 4,
                    maxWidth: '80%',
                    maxHeight: '80vh',
                    margin: '0 auto',
                    marginTop: '5%',
                    backgroundColor: 'white',
                    borderRadius: 3,
                    boxShadow: 24,
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={onClose} sx={{ color: 'primary.main' }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" component="h2" sx={{ flexGrow: 1 }}>
                        Package Options
                    </Typography>
                </Box>

                {packageOptions && packageOptions.length === 0 ? (
                    <Typography variant="h6" color="text.secondary" align="center">
                        No package options available
                    </Typography>
                ) : (
                    <Box>
                        {packageOptions.map((option) => (
                            <Box
                                key={option.id}
                                sx={{
                                    mb: 2,
                                    padding: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: 2,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        boxShadow: 3,
                                    },
                                    transition: 'box-shadow 0.3s ease',
                                }}
                                onClick={() => onSelectPackage(option)}
                            >
                                <Typography variant="h6">{option.name}</Typography>
                                <Typography variant="body2">{option.description}</Typography>
                                <Typography variant="body2">Cost: {option.cost}</Typography>
                                <Typography variant="body2">Recommended for: {option.recommended}</Typography>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default PackageInfoModal;
