import React from 'react';
import { Box, TextField, Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CollapsibleSection from '../../common/CollapsibleSection';

const EventDetailsSection = ({ 
    eventData, 
    isEditing, 
    onEdit, 
    onSave, 
    handleChange 
}) => {
    // Check if all required fields are filled
    const isComplete = Boolean(
        eventData.event.title &&
        eventData.event.eventStartDate &&
        eventData.event.eventEndDate &&
        eventData.event.vision &&
        eventData.event.type
    );

    // Handle date changes with proper field mapping
    const handleDateChange = (field, value) => {
        const isoString = value ? value.toISOString() : null;
        handleChange('event', field, isoString);
    };

    const handleCostChange = (e) => {
        const value = e.target.value;
        // Convert string to number or null if empty
        const numValue = value === '' ? null : parseInt(value, 10);
        handleChange('event', 'cost', numValue);
    };

    return (
        <CollapsibleSection
            title="Event Details"
            isComplete={isComplete}
            isEditing={isEditing}
            onEdit={onEdit}
            onSave={onSave}
            defaultExpanded={true}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Event Name"
                        value={eventData.event.title || ''}
                        onChange={(e) => handleChange('event', 'title', e.target.value)}
                        disabled={!isEditing}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <DateTimePicker
                                label="Start Date & Time"
                                value={
                                    eventData.event.eventStartDate 
                                        ? new Date(eventData.event.eventStartDate)
                                        : null
                                }
                                onChange={(newValue) => handleDateChange('eventStartDate', newValue)}
                                disabled={!isEditing}
                                sx={{ flex: 1 }}
                            />
                            <DateTimePicker
                                label="End Date & Time"
                                value={
                                    eventData.event.eventEndDate
                                        ? new Date(eventData.event.eventEndDate)
                                        : null
                                }
                                onChange={(newValue) => handleDateChange('eventEndDate', newValue)}
                                disabled={!isEditing}
                                sx={{ flex: 1 }}
                            />
                        </Box>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Event Vision/Purpose"
                        value={eventData.event.vision || ''}
                        onChange={(e) => handleChange('event', 'vision', e.target.value)}
                        disabled={!isEditing}
                        multiline
                        rows={3}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Event Type"
                        value={eventData.event.type || ''}
                        onChange={(e) => handleChange('event', 'type', e.target.value)}
                        disabled={!isEditing}
                        required
                        helperText="e.g., Conference, Workshop, Networking"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Cost"
                        value={eventData.event.cost || ''}
                        onChange={handleCostChange}
                        disabled={!isEditing}
                        type="number"
                        InputProps={{
                            startAdornment: '$',
                        }}
                    />
                </Grid>
            </Grid>
        </CollapsibleSection>
    );
};

export default EventDetailsSection; 