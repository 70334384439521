import React from 'react'
import { Chip } from '@mui/material'

const InternalLink = ({ label, link, ...props }) => {
    const handleClick = (event) => {
        event.preventDefault()
        const sectionId = link.replace('internal:', '')
        if (window.scrollToSection) {
            window.scrollToSection(sectionId)
        }
    }

    return (
        <Chip
            label={label}
            onClick={handleClick}
            component="a"
            href={link}
            clickable
            {...props}
        />
    )
}

export default InternalLink
