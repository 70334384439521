import React from 'react';
import { Box, TextField, Typography, Grid } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CollapsibleSection from 'components/common/CollapsibleSection';

// Keep COLORS constant for pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const AudienceSection = ({ 
    eventData, 
    handleChange, 
    isEditing, 
    onEdit, 
    onSave,
    highlightedSection,
    audienceInfoRef 
}) => {
    // Check if required fields are filled
    const isComplete = Boolean(
        eventData.event.target_audience &&
        eventData.event.guestCount &&
        eventData.event.audience_data?.length > 0
    );

    return (
        <CollapsibleSection
            title="Audience Information"
            isComplete={isComplete}
            isEditing={isEditing}
            onEdit={onEdit}
            onSave={onSave}
        >
            <Grid container spacing={3}>
                <Grid ref={audienceInfoRef} item xs={12} md={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Target Audience"
                        value={Array.isArray(eventData.event.target_audience) 
                            ? eventData.event.target_audience.join(', ')
                            : eventData.event.target_audience || ''}
                        onChange={(e) => handleChange('event', 'target_audience', e.target.value)}
                        disabled={!isEditing}
                        helperText="e.g., Startup Founder, Investor, etc."
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Expected Attendees"
                        type="number"
                        value={eventData.event.guestCount || ''}
                        onChange={(e) =>
                            handleChange(
                                'event',
                                'guestCount',
                                parseInt(e.target.value) || 0
                            )
                        }
                        disabled={!isEditing}
                        InputProps={{
                            inputProps: { min: 0 },
                        }}
                    />
                    {/* <TextField
                        fullWidth
                        margin="normal"
                        label="Age Range"
                        value={eventData.event.demographics?.ageRange || ''}
                        onChange={(e) =>
                            handleChange('audience', 'demographics', {
                                ...eventData.event.demographics,
                                ageRange: e.target.value,
                            })
                        }
                        disabled={!isEditing}
                    /> */}
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Professional Background"
                        value={eventData.event.demographics?.background || ''}
                        onChange={(e) =>
                            handleChange('audience', 'demographics', {
                                ...eventData.event.demographics,
                                background: e.target.value,
                            })
                        }
                        disabled={!isEditing}
                        multiline
                        rows={2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                        Audience Breakdown
                    </Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    data={eventData.event.audience_data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {(eventData.event.audience_data || []).map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        </CollapsibleSection>
    );
};

export default AudienceSection; 