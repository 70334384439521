import React from 'react'
import { Grid, Card, CardContent, Typography } from '@mui/material'

const eventRequests = [
    {
        name: 'The Networking Panel',
        image: 'https://via.placeholder.com/400x200.png?text=The+Networking+Panel',
    },
    {
        name: 'Music Video Shoot',
        image: 'https://via.placeholder.com/400x200.png?text=Music+Video+Shoot',
    },
    {
        name: 'Career Fair',
        image: 'https://via.placeholder.com/400x200.png?text=Career+Fair',
    },
    {
        name: 'Product Launch',
        image: 'https://via.placeholder.com/400x200.png?text=Product+Launch',
    },
    // Add more event requests as needed
]

const EventRequests = () => {
    return (
        <Grid container spacing={4} sx={{ padding: 4 }}>
            {eventRequests.map((request, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: 2,
                            '&:hover': {
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                            },
                        }}
                    >
                        <img
                            src={request.image}
                            alt={request.name}
                            style={{
                                width: '100%',
                                height: '200px',
                                objectFit: 'cover',
                                borderTopLeftRadius: '4px',
                                borderTopRightRadius: '4px',
                            }}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: 'bold' }}
                            >
                                {request.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default EventRequests
