import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { UserContext } from './contexts/UserContext'
import LandingPage from 'pages/index'
import Page404 from 'pages/page404'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import EventRentStartPage from 'pages/EventRentStartPage'
import VenueFormPage from 'pages/VenueOwner/AddVenue'
import VenuePage from './pages/VenuePage'
import MarketplacePage from 'pages/marketplace'
// import MyVenues from "components/UserProfile/MyVenues";
import ShoppingCartPage from 'pages/ShoppingCart'
import Box from '@mui/material/Box'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import EventHostNavBar from 'components/NavBar/EventHostNavBar'
import StripeCheckoutSuccessPage from 'pages/StripeCheckoutSuccessRoute'
import MyEventsAnalytics from 'components/myEvents/Host/MyEventAnalytics'
import MyEventsSection from 'pages/myEventsDashboardPage'
// import MyEventsSection from "pages/myEventsDashboardPage";
import PrivateRoute from './PrivateRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { googleLogout } from '@react-oauth/google'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import EventDetailsPage from 'components/myEvents/Host/eventDetailsPage'
import ScrollToTop from 'components/ScrollToTop'
import ConditionalNavBar from 'components/NavBar/ConditionalNavBar'
import HostYourVenueWithUs from 'pages/VenueOwner/HostYourVenueWithUs'
import MyVenues from 'pages/VenueOwner/MyVenues'
import EventRequests from 'pages/VenueOwner/EventRequests'
import UserProfilePage from 'pages/UserProfilePage'
import { UserProvider } from './contexts/UserContext'
import { ConfigProvider } from './contexts/ConfigContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { getGoogleClientId } from './utils'
import Contact from './pages/LandingPage/Contact'
import Pricing from './pages/LandingPage/Pricing'
import VenueOwnerHomePage from 'pages/VenueOwner/VenueOwnerHomePage'

// Add your Google Client ID from your Google Cloud Console
const GOOGLE_CLIENT_ID = getGoogleClientId()

// Create a new BetaRoute component for protected routes
const BetaRoute = ({ component: Component, render, ...rest }) => {
    const { user, loading, hasRole } = useContext(UserContext)
    const location = useLocation()

    return (
        <Route
            {...rest}
            render={(props) => {
                if (loading) {
                    return <div>Loading...</div>
                }

                // Allow access if user has either user or admin role
                const hasBetaAccess = user && hasRole(['user', 'admin'])

                if (!hasBetaAccess) {
                    //   toast.info('This feature requires beta access. Please request access to continue.');
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: location,
                                    message:
                                        'This is currently in private beta. Please request access to continue.',
                                },
                            }}
                        />
                    )
                }

                return render ? (
                    render(props)
                ) : Component ? (
                    <Component {...props} />
                ) : null
            }}
        />
    )
}

function App() {
    const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
    `

    // Initialize state from localStorage
    const [cartCount, setCartCount] = useState(() => {
        const savedCount = localStorage.getItem('cartCount')
        return savedCount ? parseInt(savedCount, 10) : 0
    })

    // Update localStorage whenever cartCount changes
    useEffect(() => {
        // Read from localStorage every time the component renders
        const savedCount = localStorage.getItem('cartCount')
        setCartCount(savedCount ? parseInt(savedCount, 10) : 0)
    }, [])

    useEffect(() => {
        localStorage.setItem('cartCount', cartCount)
    }, [cartCount])

    const resetCartCount = () => {
        localStorage.setItem('cartCount', 0)
        setCartCount(0)
    }

    const [cartAddons, setCartAddons] = useState([])
    const [venue, setVenues] = useState([])

    window.onerror = function (message, source, lineno, colno, error) {
        console.error('Global error:', {
            message,
            source,
            lineno,
            colno,
            error,
        })
        return false
    }

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ConfigProvider>
                <UserProvider>
                    <Router>
                        <ToastContainer />
                        <ScrollToTop />
                        <Box mt={6}>
                            <ConditionalNavBar cartCount={cartCount} />
                        </Box>
                        <Switch>
                            {/* Public routes */}
                            <Route
                                exact
                                path="/EventRentStartPage"
                                component={EventRentStartPage}
                            />
                            <Route exact path="/" component={LandingPage} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/pricing" component={Pricing} />
                            <Route
                                exact
                                path="/index"
                                component={LandingPage}
                            />

                            {/* Protected beta routes */}
                            <BetaRoute
                                exact
                                path="/marketplace"
                                component={MarketplacePage}
                            />
                            <BetaRoute
                                exact
                                path="/addVenue"
                                component={VenueFormPage}
                            />
                            <BetaRoute
                                exact
                                path="/profile"
                                component={UserProfilePage}
                            />
                            <BetaRoute
                                path="/venue/:id"
                                render={(props) => (
                                    <VenuePage
                                        {...props}
                                        setCartCount={setCartCount}
                                        setCartAddonItems={setCartAddons}
                                        setCartVenueItems={setVenues}
                                    />
                                )}
                            />
                            <BetaRoute
                                path="/shoppingcart"
                                render={(props) => (
                                    <ShoppingCartPage
                                        {...props}
                                        cartAddons={cartAddons}
                                        venue={venue}
                                    />
                                )}
                            />
                            <BetaRoute
                                exact
                                path="/stripeCheckoutSuccessPage"
                                render={(props) => (
                                    <StripeCheckoutSuccessPage
                                        {...props}
                                        resetCartCount={resetCartCount}
                                    />
                                )}
                            />
                            <BetaRoute
                                exact
                                path="/eventanalytics"
                                component={MyEventsAnalytics}
                            />

                            {/* Routes that need both beta access and specific roles */}
                            <BetaRoute
                                exact
                                path="/myevents"
                                render={(props) => (
                                    <PrivateRoute
                                        {...props}
                                        component={MyEventsSection}
                                        roles={[
                                            'admin',
                                            'host',
                                            'venue_owner',
                                            'user',
                                        ]}
                                    />
                                )}
                            />
                            <BetaRoute
                                path="/myevents/:eventId"
                                component={EventDetailsPage}
                            />
                            <BetaRoute
                                exact
                                path="/myevents/test"
                                component={EventDetailsPage}
                            />

                            {/* Venue owner routes */}
                            <BetaRoute
                                exact
                                path="/venueOwnerHomePage"
                                component={VenueOwnerHomePage}
                            />
                            <BetaRoute
                                exact
                                path="/venueOwner/LandingPage"
                                component={HostYourVenueWithUs}
                            />
                            <BetaRoute
                                exact
                                path="/venueOwner/addVenue"
                                component={VenueFormPage}
                            />
                            <BetaRoute
                                exact
                                path="/venueOwner/myEvents"
                                component={MyEventsSection}
                            />
                            <BetaRoute
                                exact
                                path="/venueOwner/myVenues"
                                component={MyVenues}
                            />
                            <BetaRoute
                                exact
                                path="/venueOwner/EventRequests"
                                component={EventRequests}
                            />

                            {/* 404 route */}
                            <Route component={Page404} />
                        </Switch>
                    </Router>
                </UserProvider>
            </ConfigProvider>
        </GoogleOAuthProvider>
    )
}
export default App
