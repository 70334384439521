import React from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material'
import PushPinIcon from '@mui/icons-material/PushPin'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

const SponsorCard = ({
    sponsor,
    onClick,
    handleOpenMessages,
    removeSponsor,
}) => {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '40%',
                position: 'relative',
                '&:hover .delete-button': {
                    opacity: 0,
                },
            }}
            onClick={onClick}
        >
            <CardMedia
                component="img"
                height="140"
                image={
                    sponsor.company.logoUrl ?? 'https://picsum.photos/200/300'
                } // Placeholder image
                alt={sponsor.company.name}
            />
            <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                    {sponsor.company.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PushPinIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {sponsor.company.name ||
                            'Company description not available'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocalOfferIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {'Standard Sponsor Package'}
                    </Typography>
                </Box>
            </CardContent>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    display: 'flex',
                    gap: 1,
                }}
            >
                <Tooltip title="Open messages" arrow>
                    <IconButton
                        sx={{
                            color: 'blue',
                            minWidth: 'auto',
                            p: 1,
                        }}
                        onClick={() => handleOpenMessages(sponsor)}
                    >
                        <MailOutlineIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete sponsor" arrow>
                    <IconButton
                        sx={{
                            color: 'red',
                            minWidth: 'auto',
                            p: 1,
                        }}
                        onClick={() => removeSponsor(sponsor)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Card>
    )
}

export default SponsorCard
