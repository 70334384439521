import React, { useState } from 'react'
import {
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
    Tooltip,
    Card,
    Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddSponsorModal from '../../Collaborators/AddSponsorModal'
import SponsorCard from '../../Collaborators/SponsorCard'
import { getBackendUserUrl } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { toast } from 'react-toastify'
import AddonMessagePanel from 'components/Messaging/AddonMessagePanel'
import AddCustomButton from 'components/common/AddCustomButton'

const SponsorGrid = ({ eventDetails, currentUser }) => {
    const [eventSponsors, setEventSponsors] = useState(
        eventDetails.event.addons.filter(
            (addon) => addon.addonType === 'General Sponsor'
        )
    )
    const [venueSponsors, setVenueSponsors] = useState(
        eventDetails.venue.sponsors
    )
    const [modalOpen, setModalOpen] = useState(false)

    const [receiver, setReceiver] = useState(null)
    const [selectedConversation, setSelectedConversation] = useState(null)
    const [messagesPanelOpen, setMessagesPanelOpen] = useState(false)
    console.log('SponsorGrid Venue Sponsors', venueSponsors)
    console.log('SponsorGrid Event Sponsors', eventSponsors)
    console.log('SponsorGrid infoooo ', eventDetails)
    console.log('SponsorGrid currentUser ', currentUser)

    const fetchSponsorContact = async (sponsor) => {
        try {
            const companyId = sponsor.company.companyId
            const apiUrl = `${getBackendUserUrl()}/event/company_contact?company_id=${companyId}`
            const response = await axios.get(apiUrl)

            if (response.data) {
                return response.data
            } else {
                throw new Error('No contact information found for the sponsor')
            }
        } catch (err) {
            console.error('Error fetching sponsor contact:', err.message)
            toast.error('Error fetching sponsor contact information')
            return null
        }
    }

    const getMessages = async ({ eventId, senderId, receiverId }) => {
        try {
            const apiUrl = `${getBackendUserUrl()}/send-notification/get_messages`
            const response = await axios.get(apiUrl, {
                params: {
                    eventId,
                    senderId,
                    receiverId,
                },
            })

            if (response.data.messages) {
                return response.data.messages
            } else {
                return []
            }
        } catch (err) {
            console.error('Error fetching messages:', err.message)
            return []
        }
    }

    const handleOpenMessages = async (sponsor) => {
        try {
            if (!currentUser?.user?.userId) {
                toast.error('Error: Current user not properly loaded')
                return
            }

            const contactInfo = await fetchSponsorContact(sponsor)
            if (!contactInfo?.user?.userId) {
                toast.error(
                    'Cannot start conversation: No user account found for this company'
                )
                return
            }

            const eventId = eventDetails.event.eventId
            const senderId = currentUser.userId
            const receiverId = contactInfo.user.userId

            setReceiver(contactInfo)

            const messages = await getMessages({
                eventId,
                senderId,
                receiverId,
            })

            const newConversation = {
                eventId: eventId,
                participants: [
                    { userId: currentUser.userId, email: currentUser.email },
                    {
                        userId: contactInfo.user.userId,
                        email: contactInfo.user.email,
                    },
                ],
                messages: messages,
            }

            setSelectedConversation(newConversation)
            setMessagesPanelOpen(true)
        } catch (error) {
            console.error('Error in handleOpenMessages:', error)
            toast.error('Error opening conversation')
        }
    }

    const addSponsor = async (sponsor, packageId = null) => {
        console.log('AddSponsor', sponsor)

        const updatedSponsor = {
            eventAddonsId: uuidv4(),
            created_at: sponsor.created,
            eventId: eventDetails.event.eventId,
            companyId: sponsor.company.companyId || null,
            status: 'pending',
            lastSeenStatus: 'pending',
            selected: true,
            packageId: packageId || null,
            includes: sponsor.includes || null,
            type: 'General Sponsor',
            recommendedPackageId: sponsor.recommend || null,
            addOnID: sponsor.addonID || null,
        }

        console.log('Adding vendor:', updatedSponsor)
        updatedSponsor.company = sponsor.company
        setEventSponsors((prev) => [...prev, updatedSponsor])

        const apiUrl = `${getBackendUserUrl()}/event/add_vendor_for_event/${eventDetails.event.eventId}`

        try {
            // Await the axios POST request to wait for the response before proceeding
            const response = await axios.post(apiUrl, updatedSponsor)
            console.log('Sponsor added successfully:', response.data)
        } catch (error) {
            // Handle Axios error (API errors, network errors, etc.)
            if (axios.isAxiosError(error)) {
                const axiosError = error
                console.error(
                    'Axios Error: ',
                    axiosError.response?.data || axiosError.message
                )
            } else {
                // Handle other errors (unexpected errors)
                console.error('Unexpected Error: ', error)
            }
        }
    }

    const removeVendor = async (sponsor) => {
        console.log(
            'Removing Sponsor with sponsor ID:',
            sponsor.company.companyId
        )

        const apiUrl = `${getBackendUserUrl()}/event/remove_vendor_for_event/${eventDetails.event.eventId}/${sponsor.company.companyId}`

        try {
            // Await the axios DELETE request to wait for the response before proceeding
            const response = await axios.delete(apiUrl)
            console.log('Sponsor removed successfully:', response.data)

            setEventSponsors((prevDetails) =>
                prevDetails.filter(
                    (addon) =>
                        addon.company.companyId !== sponsor.company.companyId
                )
            )
        } catch (error) {
            // Handle Axios error (API errors, network errors, etc.)
            if (axios.isAxiosError(error)) {
                const axiosError = error
                console.error(
                    'Axios Error: ',
                    axiosError.response?.data || axiosError.message
                )
            } else {
                // Handle other errors (unexpected errors)
                console.error('Unexpected Error: ', error)
            }
        }
    }

    const getAvailableSponsors = () => {
        const selectedSponsorNames = eventSponsors.map(
            (addon) => addon.company.companyId
        )
        const availebleSponsorNames = venueSponsors.filter(
            (sponsor) =>
                !selectedSponsorNames.includes(sponsor.company.companyId)
        )
        return availebleSponsorNames
    }

    return (
        <Grid item xs={12} md={12} sx={{ mb: 3 }}>
            <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Sponsors
                </Typography>

                {/* Add Button */}
                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <AddCustomButton
                        text="Add Sponsor"
                        onClick={() => setModalOpen(true)}
                        tooltipText="Add a new sponsor"
                    />
                </Box>

                {/* Render Event Sponsors as Cards */}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {eventSponsors.map((sponsor) => (
                        <SponsorCard
                            key={sponsor.company.companyId}
                            sponsor={{
                                company: {
                                    companyId: sponsor.company.companyId,
                                    name: sponsor.company.name,
                                    desc:
                                        sponsor.description ||
                                        'Company description not available',
                                    logoUrl: sponsor.company.logoUrl,
                                },
                                packageDetails: {
                                    name:
                                        sponsor.addonType ||
                                        'Package info not available',
                                    cost:
                                        sponsor.price ||
                                        'No cost details available',
                                },
                            }}
                            handleOpenMessages={handleOpenMessages} // Example function
                            removeSponsor={(sponsor) => {
                                removeVendor(sponsor)
                                console.log('Remove sponsor:', sponsor)
                            }} // Example function
                        />
                    ))}
                </Box>

                {/* Add Sponsor Modal */}
                <AddSponsorModal
                    sponsors={getAvailableSponsors()}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    addSponsor={(sponsor) => {
                        setModalOpen(false)
                        addSponsor(sponsor)
                    }}
                />
            </Paper>

            {/* Addon Message Panel */}
            <AddonMessagePanel
                open={messagesPanelOpen}
                onClose={() => setMessagesPanelOpen(false)}
                conversation={selectedConversation}
                sender={currentUser}
                receiver={receiver}
                eventId={eventDetails.event.eventId}
            />
        </Grid>
    )
}

export default SponsorGrid
