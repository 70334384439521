import { toast } from 'react-toastify'

const ComingSoonPopup = ({
    message = "We're working on something magical.",
}) => {
    const showComingSoon = () => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '24px', marginRight: '10px' }}>
                    ✨
                </span>
                <div>
                    <div style={{ fontWeight: 'bold' }}>Coming Soon!</div>
                    <div style={{ fontSize: '0.9em' }}>{message}</div>
                </div>
            </div>,
            {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            }
        )
    }

    return { showComingSoon }
}

export default ComingSoonPopup
