// PrivateRoute.js
import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from './contexts/UserContext'

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { user, loading, hasRole } = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (loading) {
                    // You might want to show a loading spinner here
                    return <div>Loading...</div>
                }

                if (!user) {
                    // Not logged in, redirect to login page
                    return (
                        <Redirect
                            to={{
                                pathname: '/eventrentstartpage',
                                state: { from: props.location },
                            }}
                        />
                    )
                }

                // Check if route is restricted by role
                if (roles && !hasRole(roles)) {
                    // Role not authorized, redirect to home page
                    return <Redirect to={{ pathname: '/eventrentstartpage' }} />
                }

                // Authorized, render component
                return <Component {...props} />
            }}
        />
    )
}

export default PrivateRoute
