import React, { useState } from 'react'
import {
    Modal,
    Box,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PushPinIcon from '@mui/icons-material/PushPin'
import CategoryIcon from '@mui/icons-material/Category'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PackageInfoModal from './PackageInfoModal' // Import the second modal

const AddVendorModal = ({ vendors, open, onClose, addVendors }) => {
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [showPackageOptions, setShowPackageOptions] = useState(false) // State to control package options modal

    const handleVendorClick = (vendor) => {
        setSelectedVendor(vendor)
        // If the vendor has package options show that, otherwise just add the vendor.
        if (vendor.company.packageInfo.options) {
            setShowPackageOptions(true)
        } else {
            addVendors(vendor, null)
            handleClosePackageOptionsModal()
        }
    }

    const handleClosePackageOptionsModal = () => {
        setShowPackageOptions(false) // Close package options modal
        setSelectedVendor(null) // Reset selected vendor
        onClose()
    }

    const handleSelectPackage = (selectedPackage) => {
        if (selectedVendor) {
            // Call addVendors with the selected addon and packageId
            addVendors(selectedVendor, selectedPackage.id) // Assuming selectedPackage has an id
            handleClosePackageOptionsModal() // Close the modal after selection
        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    padding: 4,
                    maxWidth: '80%',
                    maxHeight: '80vh',
                    margin: '0 auto',
                    marginTop: '5%',
                    backgroundColor: 'white',
                    borderRadius: 3,
                    boxShadow: 24,
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" component="h2" gutterBottom>
                    Vendors
                </Typography>

                {vendors.length === 0 ? (
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        align="center"
                    >
                        No vendors available
                    </Typography>
                ) : (
                    <Grid container spacing={2}>
                        {vendors.map((vendor) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={vendor.addonId}
                            >
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        boxShadow: 3,
                                        borderRadius: 2,
                                        '&:hover': {
                                            boxShadow: 6,
                                            cursor: 'pointer',
                                        },
                                        transition: 'box-shadow 0.3s ease',
                                    }}
                                    onClick={() => handleVendorClick(vendor)}
                                >
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image={
                                            vendor.company.logoUrl ??
                                            'https://picsum.photos/200/300'
                                        }
                                        alt={vendor.company.name}
                                    />
                                    <CardContent sx={{ paddingBottom: 2 }}>
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            gutterBottom
                                            sx={{ fontWeight: 600 }}
                                        >
                                            {vendor.company.name}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                            }}
                                        >
                                            <PushPinIcon
                                                sx={{
                                                    mr: 1,
                                                    fontSize: 'small',
                                                    color: 'primary.main',
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {vendor.company.desc ||
                                                    'No description available'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                            }}
                                        >
                                            <CategoryIcon
                                                sx={{
                                                    mr: 1,
                                                    fontSize: 'small',
                                                    color: 'primary.main',
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Type:{' '}
                                                {vendor.addonType ||
                                                    'Type not specified'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                            }}
                                        >
                                            <LocationOnIcon
                                                sx={{
                                                    mr: 1,
                                                    fontSize: 'small',
                                                    color: 'primary.main',
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Location:{' '}
                                                {vendor.company.location ||
                                                    'Location not available'}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {showPackageOptions && selectedVendor && (
                    <PackageInfoModal
                        open={showPackageOptions}
                        onClose={handleClosePackageOptionsModal}
                        packageOptions={
                            selectedVendor.company.packageInfo.options || []
                        }
                        onSelectPackage={handleSelectPackage} // Pass the selection handler
                    />
                )}
            </Box>
        </Modal>
    )
}

export default AddVendorModal
