import config from './config/config'

export function getBackendUserUrl() {
    return config.backendUserUrl
}

export function getTestAccounts() {
    return config.testAccounts
}

export function getGoogleClientId() {
    console.log('!!!!!!!!!!!')
    console.log(config.GOOGLE_CLIENT_ID)
    console.log(process.env.REACT_APP_GOOGLE_CLIENTID_LOGIN)
    console.log('!!!!!!!!!!!')
    return config.GOOGLE_CLIENT_ID
}

export function getApiUrl() {
    return config.API_URL
}

export function getMailjetKeys() {
    return {
        public: config.MJ_APIKEY_PUBLIC,
        private: config.MJ_APIKEY_PRIVATE,
    }
}

export function haversine(lat1, lon1, lat2, lon2) {
    const R = 3956 // Radius of the Earth in miles

    const dlat = (lat2 - lat1) * (Math.PI / 180) // Convert degrees to radians
    const dlon = (lon2 - lon1) * (Math.PI / 180) // Convert degrees to radians

    const a =
        Math.sin(dlat / 2) ** 2 +
        Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dlon / 2) ** 2
    const c = 2 * Math.asin(Math.sqrt(a))

    return R * c // Distance in miles
}

export async function fetchGeocodeData(address, apiKey) {
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_GEOENCODING_API_KEY}`
    )
    const data = await response.json()
    if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location
        return [lat, lng]
    } else {
        console.error(
            'Geocode was not successful for the following reason:',
            data.status
        )
    }
}
