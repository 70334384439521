import React from 'react';
import { Box, TextField, IconButton, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CollapsibleSection from '../../common/CollapsibleSection';

const SpeakersSection = ({ 
    eventData, 
    setEventData, 
    isEditing, 
    onEdit, 
    onSave 
}) => {
    // Check if all required fields are filled
    const isComplete = Boolean(
        (eventData.event.format?.linkedinUrls?.length > 0) || 
        (eventData.event.speakers_and_presenters?.length > 0)
    );

    const handleAddLinkedIn = () => {
        setEventData((prev) => ({
            ...prev,
            event: {
                ...prev.event,
                format: {
                    ...prev.event.format,
                    linkedinUrls: [
                        '',
                        ...(prev.event.format?.linkedinUrls || []),
                    ],
                },
            },
        }));
    };

    const handleUrlChange = (index, newValue) => {
        const newLinkedInUrls = [...(eventData.event.format?.linkedinUrls || [])];
        newLinkedInUrls[index] = newValue;
        setEventData((prev) => ({
            ...prev,
            event: {
                ...prev.event,
                format: {
                    ...prev.event.format,
                    linkedinUrls: newLinkedInUrls,
                },
            },
        }));
    };

    const handleDeleteUrl = (url) => {
        const isBackendUrl = eventData.event.speakers_and_presenters?.includes(url);

        if (isBackendUrl) {
            const newSpeakersAndPresenters = eventData.event.speakers_and_presenters.filter(
                (item) => item !== url
            );
            setEventData((prev) => ({
                ...prev,
                event: {
                    ...prev.event,
                    speakers_and_presenters: newSpeakersAndPresenters,
                },
            }));
        } else {
            const newLinkedInUrls = eventData.event.format?.linkedinUrls.filter(
                (item) => item !== url
            );
            setEventData((prev) => ({
                ...prev,
                event: {
                    ...prev.event,
                    format: {
                        ...prev.event.format,
                        linkedinUrls: newLinkedInUrls,
                    },
                },
            }));
        }
    };

    return (
        <CollapsibleSection
            title="Speakers and Presenters"
            isComplete={isComplete}
            isEditing={isEditing}
            onEdit={onEdit}
            onSave={onSave}
        >
            <Grid container spacing={2}>
                {isEditing && (
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <IconButton onClick={handleAddLinkedIn}>
                                <AddIcon />
                            </IconButton>
                            <Typography variant="subtitle1">
                                Add LinkedIn URL
                            </Typography>
                        </Box>
                    </Grid>
                )}

                {[
                    ...(eventData.event.format?.linkedinUrls || []),
                    ...(eventData.event.speakers_and_presenters || []),
                ].map((url, index) => (
                    <Grid item xs={12} key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                fullWidth
                                label="LinkedIn URL"
                                value={url}
                                onChange={(e) => handleUrlChange(index, e.target.value)}
                                error={url && !/^https:\/\/(www\.)?linkedin\.com\/.*$/.test(url)}
                                helperText={
                                    url && !/^https:\/\/(www\.)?linkedin\.com\/.*$/.test(url)
                                        ? 'Please enter a valid LinkedIn URL.'
                                        : ''
                                }
                                disabled={!isEditing}
                            />
                            {isEditing && (
                                <IconButton onClick={() => handleDeleteUrl(url)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </CollapsibleSection>
    );
};

export default SpeakersSection; 