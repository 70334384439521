import React, { createContext, useContext, useState, useEffect } from 'react'
import config from '../config/config'

const ConfigContext = createContext()

export function ConfigProvider({ children }) {
    const [currentConfig, setCurrentConfig] = useState(config)

    useEffect(() => {
        // You can load additional config here if needed
        // For example, fetching some config from an API
    }, [])

    return (
        <ConfigContext.Provider
            value={{ config: currentConfig, setConfig: setCurrentConfig }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

export function useConfig() {
    return useContext(ConfigContext)
}
