import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@mui/material'

import BudgetChart from '../components/myEvents/BudgetChart'
import {
    Box,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Button,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Chip,
} from '@mui/material'

import EventCard from '../components/myEvents/Host/EventCard'
import { UserContext } from '../contexts/UserContext'
import { getBackendUserUrl } from '../utils'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import BarChartIcon from '@mui/icons-material/BarChart'
import EventIcon from '@mui/icons-material/Event'
import PeopleIcon from '@mui/icons-material/People'
import EventNoteIcon from '@mui/icons-material/EventNote'
import InfoIcon from '@mui/icons-material/Info'

const MyEventsSection = ({}) => {
    const { user, loading } = useContext(UserContext)
    const history = useHistory()
    const [events, setEvents] = useState([])
    const [error, setError] = useState(null)
    const [metrics, setMetrics] = useState(null)
    const [showBudgetDialog, setShowBudgetDialog] = useState(false)
    const [annualBudget, setAnnualBudget] = useState('')
    const [recommendations, setRecommendations] = useState(null)
    const [loadingRecommendations, setLoadingRecommendations] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [eventToDelete, setEventToDelete] = useState(null)

    useEffect(() => {
        if (user && user.userId) {
            fetchEvents()
        }
    }, [user])

    useEffect(() => {
        if (user?.userId) {
            fetchDashboardMetrics()
        }
    }, [user])

    const fetchEvents = async () => {
        try {
            const eventsData = await getEventsByHost(user.userId)
            setEvents(eventsData)
            if (eventsData.length > 0) {
                await fetchRecommendations(eventsData)
            }
        } catch (error) {
            console.error('Failed to fetch events:', error)
        }
    }

    const fetchDashboardMetrics = async () => {
        try {
            const response = await fetch(
                `${getBackendUserUrl()}/event/dashboard_metrics/${user.userId}`
            )
            if (!response.ok) {
                throw new Error('Failed to fetch metrics')
            }
            const data = await response.json()
            setMetrics(data)

            if (data.is_new_user) {
                // setShowBudgetDialog(true);
                setAnnualBudget(data.annual_budget.toString())
            }
        } catch (error) {
            console.error('Failed to fetch metrics:', error)
        }
    }

    const templateEvents = async () => {
        console.log('templateEvents')
        const apiUrl = `${getBackendUserUrl()}/event/get_template_events`
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.detail || 'Failed to retrieve events')
            }
            return await response.json()
        } catch (error) {
            console.error('Error retrieving template events:', error.message)
            throw error
        }
    }

    const getEventsByHost = async (hostId) => {
        const apiUrl = `${getBackendUserUrl()}/event/events_by_host/${hostId}`
        console.log('apiUrl', apiUrl)
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.detail || 'Failed to retrieve events')
            }
            let eventsData = await response.json()
            if (user.role.includes('user')) {
                const templateEventsData = await templateEvents()
                console.log('templateEventsData', templateEventsData)
                eventsData = eventsData.concat(templateEventsData)
                console.log('eventsData', eventsData)
            }
            return eventsData
        } catch (error) {
            console.error('Error retrieving events:', error.message)
            throw error
        }
    }

    const handleClick = () => {
        history.push(`myevents/test`)
    }

    const handleEventCardClick = (event) => {
        history.push(`/myevents/${event.eventId}`)
    }

    const handleAddEvent = () => {
        history.push('/eventrentstartpage')
    }

    const handleBudgetUpdate = async (newBudget) => {
        try {
            console.log('Updating budget to:', newBudget)
            const response = await fetch(
                `${getBackendUserUrl()}/event/update_budget`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        host_id: user.userId,
                        annual_budget: newBudget,
                    }),
                }
            )

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.detail || 'Failed to update budget')
            }

            setAnnualBudget(newBudget.toString())

            await fetchDashboardMetrics()
        } catch (error) {
            console.error('Failed to update budget:', error)
        }
    }

    const handleDeleteEvent = async (event) => {
        setEventToDelete(event)
        setDeleteDialogOpen(true)
    }

    const confirmDelete = async () => {
        try {
            await fetch(`${getBackendUserUrl()}/event/delete_event/${eventToDelete.eventId}`, {
                method: 'DELETE',
            })
            
            // Remove from local state
            setEvents(events.filter(e => e.eventId !== eventToDelete.eventId))
            
            // Show success message
            toast.success('Event deleted successfully')
        } catch (error) {
            console.error('Failed to delete event:', error)
            toast.error('Failed to delete event')
        } finally {
            setDeleteDialogOpen(false)
            setEventToDelete(null)
        }
    }

    const sortedEvents = events.sort((a, b) =>
        dayjs(a.eventStartDate).diff(dayjs(b.eventStartDate))
    )
    const currentDate = dayjs()
    const upcomingEvents = sortedEvents.filter((event) =>
        dayjs(event.eventStartDate).isAfter(currentDate)
    )
    const pastEvents = sortedEvents.filter((event) =>
        dayjs(event.eventStartDate).isBefore(currentDate)
    )
    console.log('sortedEvents', sortedEvents)
    console.log('upcomingEvents', upcomingEvents)
    console.log('pastEvents', pastEvents)

    const fetchRecommendations = async (events) => {
        // Don't fetch if no events
        if (!events || events.length === 0) return;
        
        setLoadingRecommendations(true);
        try {
            // Get the event IDs as a sorted string for comparison
            const currentEventIds = events.map(e => e.eventId).sort().join(',');
            
            // Check if we have this stored in localStorage
            const storedRecs = localStorage.getItem('aiRecommendations');
            const storedData = storedRecs ? JSON.parse(storedRecs) : null;
            
            // Use stored recommendations if they exist and are recent (less than 24 hours old)
            if (storedData && 
                storedData.eventIds === currentEventIds && 
                (new Date().getTime() - new Date(storedData.timestamp).getTime()) < 24 * 60 * 60 * 1000) {
                setRecommendations(storedData.recommendations);
                return;
            }

            const response = await fetch(`${getBackendUserUrl()}/openai/generate-dashboard-recommendations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ events }),
            });
            
            if (!response.ok) throw new Error('Failed to fetch recommendations');
            
            const data = await response.json();
            setRecommendations(data.recommendations);
            
            // Store in localStorage
            localStorage.setItem('aiRecommendations', JSON.stringify({
                recommendations: data.recommendations,
                eventIds: currentEventIds,
                timestamp: new Date().toISOString()
            }));
            
        } catch (error) {
            console.error('Error fetching recommendations:', error);
            toast.error('Failed to load AI recommendations');
        } finally {
            setLoadingRecommendations(false);
        }
    };

    const getRecommendationIcon = (type) => {
        switch (type) {
            case 'timing':
                return <AccessTimeIcon color="primary" />
            case 'cost':
                return <MonetizationOnIcon color="success" />
            case 'planning':
                return <EventNoteIcon color="info" />
            case 'engagement':
                return <PeopleIcon color="secondary" />
            default:
                return <InfoIcon />
        }
    }

    if (loading) {
        return (
            <Container sx={{ mt: 4, mb: 4 }}>
                <Typography>Loading...</Typography>
            </Container>
        )
    }

    if (!user) {
        history.push('/login') // Redirect to login page
        return null
    }

    return (
        <div className="container mt-4">
            <Container sx={{ mt: 4, mb: 4 }}>
                {/* Welcome Section */}
                <Typography variant="h3" component="h1" sx={{ mb: 4 }}>
                    Welcome {user?.firstName || 'Guest'}
                </Typography>

                <Box sx={{ my: 4 }}>
                    <hr style={{ border: '1px solid #e0e0e0' }} />
                </Box>

                {/* Events Section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="h4" component="h2">
                        Your Upcoming Events
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddEvent}
                        sx={{
                            borderRadius: 20,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            boxShadow: 2,
                        }}
                    >
                        Add Event
                    </Button>
                </Box>

                <Grid container spacing={4}>
                    {upcomingEvents.map((event) => (
                        <Grid item xs={12} sm={6} md={4} key={event.eventId}>
                            <EventCard
                                event={event}
                                onClick={() => handleEventCardClick(event)}
                                onDelete={handleDeleteEvent}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ mt: 6 }}
                >
                    Your Past Events
                </Typography>
                <Grid container spacing={4}>
                    {pastEvents.map((event) => (
                        <Grid item xs={12} sm={6} md={4} key={event.eventId}>
                            <EventCard
                                event={event}
                                onClick={() => handleEventCardClick(event)}
                                onDelete={handleDeleteEvent}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ my: 4 }}>
                    <hr style={{ border: '1px solid #e0e0e0' }} />
                </Box>
                {/* At A Glance Section */}
                    <Grid item xs={12}>
                         <Paper
                             elevation={3}
                            sx={{
                                p: 3,
                                marginBottom: 4,
                                borderRadius: 2,
                                background:
                                    'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h3"
                                gutterBottom
                                sx={{
                                    fontWeight: 600,
                                    color: '#2c3e50',
                                }}
                            >
                                At A Glance
                            </Typography>
                            {metrics ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }}>
                                        <MonetizationOnIcon
                                            sx={{
                                                fontSize: 40,
                                                mb: 1,
                                                color: '#27ae60',
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Total Spend
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            $
                                            {(
                                                metrics.total_spend || 0
                                            ).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }}>
                                        <BarChartIcon
                                            sx={{
                                                fontSize: 40,
                                                mb: 1,
                                                color: '#2980b9',
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Avg Spend
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            $
                                            {(
                                                metrics.avg_spend || 0
                                            ).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }}>
                                        <EventIcon
                                            sx={{
                                                fontSize: 40,
                                                mb: 1,
                                                color: '#8e44ad',
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Events
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {metrics.event_count || 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }}>
                                        <PeopleIcon
                                            sx={{
                                                fontSize: 40,
                                                mb: 1,
                                                color: '#e67e22',
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Attendees
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {metrics.total_attendees || 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        p: 3,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Paper>
                    </Grid>

                {/* Budget Chart Section */}
                <Grid item xs={12} md={6}>
                        <BudgetChart
                            annualBudget={metrics?.annual_budget || 0}
                            totalSpend={metrics?.total_spend || 0}
                            onBudgetUpdate={handleBudgetUpdate}
                        />
                </Grid>

                {/* Budget Setup Dialog */}
                <Dialog
                    open={showBudgetDialog}
                    onClose={() => setShowBudgetDialog(false)}
                >
                    <DialogTitle
                        sx={{
                            background:
                                'linear-gradient(145deg, #2980b9 0%, #3498db 100%)',
                            color: 'white',
                        }}
                    >
                        Welcome! Let's Set Up Your Annual Budget
                    </DialogTitle>
                    <DialogContent sx={{ mt: 2, minWidth: 400 }}>
                        <Typography variant="body1" gutterBottom>
                            Setting a budget helps us provide better
                            recommendations and tracking for your events.
                        </Typography>
                        <TextField
                            fullWidth
                            label="Annual Budget"
                            type="number"
                            value={annualBudget}
                            onChange={(e) => setAnnualBudget(e.target.value)}
                            InputProps={{
                                startAdornment: <Typography>$</Typography>,
                            }}
                            sx={{ mt: 2 }}
                        />
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button
                            onClick={() => setShowBudgetDialog(false)}
                            sx={{ color: '#7f8c8d' }}
                        >
                            Skip for Now
                        </Button>
                        <Button
                            onClick={handleBudgetUpdate}
                            variant="contained"
                            sx={{
                                background:
                                    'linear-gradient(145deg, #2980b9 0%, #3498db 100%)',
                                '&:hover': {
                                    background:
                                        'linear-gradient(145deg, #2472a4 0%, #2980b9 100%)',
                                },
                            }}
                        >
                            Set Budget
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* AI Recommendations Section */}
                <Grid item xs={12} sx={{ mt: 4 }}>
                    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <SmartToyIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
                                AI-Powered Recommendations
                            </Typography>
                        </Box>
                        
                        {loadingRecommendations ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                <CircularProgress />
                            </Box>
                        ) : recommendations && recommendations.length > 0 ? (
                            <List>
                                {recommendations.map((rec, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            {getRecommendationIcon(rec.type)}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={rec.title}
                                            secondary={rec.description}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                Host some events to get AI-powered recommendations!
                            </Typography>
                        )}
                        <ListItem>
                            <ListItemIcon>
                                <MonetizationOnIcon sx={{ color: 'success.main' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Optimize Costs"
                                secondary="Utilize our partnerships feature to decrease overall event costs by an estimated 15%."
                            />
                        </ListItem>
                    </Paper>
                </Grid>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                >
                    <DialogTitle>Delete Event</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete "{eventToDelete?.title}"? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={confirmDelete}
                            color="error"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Dashboard component remains commented out */}
            </Container>
        </div>
    )
}

export default MyEventsSection
