import React, { useState } from 'react'

import { Button, Menu, MenuItem, Box } from '@mui/material'
import {
    BiSort,
    BiSortDown,
    BiSortUp,
    BiDownArrowAlt,
    BiUpArrowAlt,
} from 'react-icons/bi'

const SortDropdown = ({ setSort }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSort = (sortType) => {
        setSort(sortType)
        handleClose()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                // padding: '20px',
                justifyContent: 'flex-end',
            }}
        >
            <Button
                aria-controls="sort-menu"
                aria-haspopup="true"
                onClick={handleClick}
                // variant="outlined"
                startIcon={<BiSort />}
            >
                Sort
            </Button>
            <Menu
                id="sort-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleSort('oldest')}>
                    Oldest <BiDownArrowAlt />
                </MenuItem>
                <MenuItem onClick={() => handleSort('newest')}>
                    Newest <BiUpArrowAlt />
                </MenuItem>
                <MenuItem onClick={() => handleSort('higherPrice')}>
                    Price <BiSortDown />
                </MenuItem>
                <MenuItem onClick={() => handleSort('lowerPrice')}>
                    Price <BiSortUp />
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default SortDropdown
