import axios from 'axios'
import React, { useState } from 'react'
import {
    Grid,
    Paper,
    Typography,
    Box,
    Tooltip,
    IconButton,
    Card
} from '@mui/material'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import AddonMessagePanel from '../../Messaging/AddonMessagePanel'
import { getBackendUserUrl } from '../../../utils'
import { v4 as uuidv4 } from 'uuid'
import VendorCard from '../../Collaborators/VendorCard'
import AddVendorModal from '../../Collaborators/AddVendorModal'
import AddCustomButton from '../../common/AddCustomButton'

const AddonsGrid = ({ eventDetails, onTagClick, currentUser }) => {
    const [addonDetails, setAddonDetails] = useState(
        eventDetails?.event.addons?.filter(addon => addon.addonType !== "General Sponsor") || []
    )
    const [venueAddons, setVenueAddons] = useState(
        eventDetails?.venue.addons || []
    )
    const [messagesPanelOpen, setMessagesPanelOpen] = useState(false)

    const [receiver, setReceiver] = useState(null)

    const [selectedConversation, setSelectedConversation] = useState(null)

    const [openAddVendorModal, setOpenAddVendorModal] = useState(false)

    const fetchAddonContact = async (addon) => {
        try {
            // Check if addon has companyId
            if (!addon.company?.companyId) {
                return
            }

            const companyId = addon.company.companyId
            const apiUrl =
                getBackendUserUrl() +
                `/event/company_contact?company_id=${companyId}`
            const response = await axios.get(apiUrl)

            if (response.data) {
                console.log('Addon contact information:', response.data)
                return response.data
            } else {
                throw new Error('No contact information found for the addon')
            }
        } catch (err) {
            console.error('Error fetching addon contact:', err.message)
            if (err.response) {
                console.error('Error response:', err.response.data)
            }
            return null
        }
    }

    const getMessages = async ({ eventId, senderId, receiverId }) => {
        try {
            const apiUrl =
                getBackendUserUrl() + `/send-notification/get_messages`
            console.log('Fetching messages with params:', {
                eventId,
                senderId,
                receiverId,
            })

            const response = await axios.get(apiUrl, {
                params: {
                    eventId,
                    senderId,
                    receiverId,
                },
            })

            console.log('Raw response:', response) // Log the full response

            if (response.data.messages) {
                console.log('Messages found:', response.data.messages)
                return response.data.messages
            } else {
                console.warn('Unexpected response format:', response.data)
                return []
            }
        } catch (err) {
            console.error('Error fetching messages:', err.message)
            return []
        }
    }

    const handleOpenMessages = async (addon) => {
        try {
            if (!currentUser?.user?.userId) {
                toast.error('Error: Current user not properly loaded')
                return
            }

            const contactInfo = await fetchAddonContact(addon)
            console.log('Contact info received:', contactInfo)

            if (!contactInfo?.user?.userId) {
                console.error('No user account found for this company')
                toast.error(
                    'Cannot start conversation: No user account found for this company'
                )
                return
            }

            const eventId = eventDetails.event.eventId
            const senderId = currentUser.userId
            const receiverId = contactInfo.user.userId

            // Set receiver first
            setReceiver(contactInfo)

            // Fetch messages and wait for them
            const messages = await getMessages({
                eventId,
                senderId,
                receiverId,
            })
            console.log('Messages received:', messages)

            if (!Array.isArray(messages)) {
                console.error('Invalid messages format received:', messages)
                toast.error('Error loading messages')
                return
            }

            // Create conversation object
            const newConversation = {
                eventId: eventId,
                participants: [
                    { userId: currentUser.userId, email: currentUser.email },
                    {
                        userId: contactInfo.user.userId,
                        email: contactInfo.user.email,
                    },
                ],
                messages: messages,
            }

            // Set conversation and wait for state update
            await new Promise((resolve) => {
                setSelectedConversation(newConversation)
                setTimeout(resolve, 100) // Give React time to update state
            })

            console.log('Conversation set:', newConversation)
            setMessagesPanelOpen(true)
        } catch (error) {
            console.error('Error in handleOpenMessages:', error)
            toast.error('Error opening conversation')
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)

    // Filter out already selected companies
    const getAvailableAddons = () => {
        const selectedCompanyNames = addonDetails.map(
            (addon) => addon.company.companyId
        )
        const availableAddons = venueAddons.filter(
            (addon) => !selectedCompanyNames.includes(addon.company.companyId)
        )
        return availableAddons
    }

    const openAddVendorMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const addVendors = async (selectedAddon, packageId) => {
        selectedAddon.status = 'pending'
        selectedAddon.lastSeenStatus = 'pending'
        console.log('Selected Addon', selectedAddon)

        const updatedAddon = {
            eventAddonsId: uuidv4(),
            created_at: selectedAddon.created,
            eventId: eventDetails.event.eventId,
            companyId: selectedAddon.company.companyId || null,
            price: selectedAddon.price,
            status: 'pending',
            lastSeenStatus: 'pending',
            selected: true,
            packageId: packageId || null,
            includes: selectedAddon.includes || null,
            type: selectedAddon.addonType,
            recommendedPackageId: selectedAddon.recommend || null,
            addOnID: selectedAddon.addonID || null,
        }

        console.log('Adding vendor:', updatedAddon)

        const apiUrl = `${getBackendUserUrl()}/event/add_vendor_for_event/${eventDetails.event.eventId}`

        try {
            // Await the axios POST request to wait for the response before proceeding
            const response = await axios.post(apiUrl, updatedAddon)
            console.log('Vendor added successfully:', response.data)

            // Add the new addon to the existing list of addon details (using the correct state updater)
            updatedAddon.company = selectedAddon.company
            updatedAddon.company.packageId = packageId
            setAddonDetails((prevDetails) => [...prevDetails, updatedAddon])
            console.log(addonDetails)
            // Close the modal or perform additional actions
            handleClose()
        } catch (error) {
            // Handle Axios error (API errors, network errors, etc.)
            if (axios.isAxiosError(error)) {
                const axiosError = error
                console.error(
                    'Axios Error: ',
                    axiosError.response?.data || axiosError.message
                )
            } else {
                // Handle other errors (unexpected errors)
                console.error('Unexpected Error: ', error)
            }
        }
    }

    const getPackageDetails = (selectedAddon) => {
        const packageId = selectedAddon.company.packageId
        let packageDetails = {
            id: 'nil',
            cost: 'No cost details available',
            name: 'Basic',
            description: 'No description available',
            recommended: 'No description available',
        }

        // Find the venueAddon that has a matching packageId
        venueAddons.forEach((venueAddon) => {
            if (venueAddon.company.packageInfo) {
                venueAddon.company.packageInfo.options.forEach(
                    (packageOption) => {
                        if (packageOption.id == packageId) {
                            packageDetails = packageOption
                        }
                    }
                )
            }
        })

        return packageDetails
    }


    const removeVendor = async (selectedAddOn) => {
        console.log('Removing Vendor with ID:', selectedAddOn.company.companyId)

        const apiUrl = `${getBackendUserUrl()}/event/remove_vendor_for_event/${eventDetails.event.eventId}/${selectedAddOn.company.companyId}`

        try {
            // Await the axios DELETE request to wait for the response before proceeding
            const response = await axios.delete(apiUrl)
            console.log('Vendor removed successfully:', response.data)

            // Remove the vendor from the existing list of addon details
            setAddonDetails((prevDetails) =>
                prevDetails.filter(
                    (addon) =>
                        addon.company.companyId !==
                        selectedAddOn.company.companyId
                )
            )

            // Close the modal or perform additional actions if necessary
            handleClose()
        } catch (error) {
            // Handle Axios error (API errors, network errors, etc.)
            if (axios.isAxiosError(error)) {
                const axiosError = error
                console.error(
                    'Axios Error: ',
                    axiosError.response?.data || axiosError.message
                )
            } else {
                // Handle other errors (unexpected errors)
                console.error('Unexpected Error: ', error)
            }
        }
    }

    const handleOpenAddVendorModal = () => {
        setOpenAddVendorModal(true)
    }

    const handleCloseAddVendorModal = () => {
        setOpenAddVendorModal(false)
    }

    return (
        <Card sx={{ width: '100%' }}>
            <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Vendors
                </Typography>
                {/* Add Button */}
                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <AddCustomButton
                        text="Add Vendors"
                        onClick={handleOpenAddVendorModal}
                        tooltipText="Add a new vendor"
                    />
                </Box>
                {/* Use Grid container to wrap VendorCards */}
                <Grid container spacing={2}>
                    {Array.isArray(addonDetails) &&
                        addonDetails
                            .filter((addon) => addon.status !== 'cancelled')
                            .map((addon, index) => (
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <VendorCard
                                        eventAddon={addon}
                                        onClick={() => {}}
                                        packageDetails={getPackageDetails(
                                            addon
                                        )}
                                        removeVendor={removeVendor}
                                        handleOpenMessages={handleOpenMessages}
                                    />
                                </Grid>
                            ))}
                </Grid>
            </Paper>
            <AddonMessagePanel
                open={messagesPanelOpen}
                onClose={() => setMessagesPanelOpen(false)}
                conversation={selectedConversation}
                onTagClick={onTagClick}
                sender={currentUser}
                receiver={receiver}
                eventId={eventDetails.event.eventId}
            />
            <AddVendorModal
                vendors={getAvailableAddons()}
                open={openAddVendorModal}
                onClose={handleCloseAddVendorModal}
                addVendors={addVendors}
            />
        </Card>
    )
}

export default AddonsGrid
