import React from 'react';
import { Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddCustomButton = ({ text, onClick, tooltipText }) => {
    return (
        <Tooltip title={tooltipText}>
            <Button
                onClick={onClick}
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                    color: '#1976d2',
                    borderColor: '#1976d2',
                    borderRadius: '20px',
                    padding: '4px 12px',
                    fontSize: '0.75rem',
                    '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                        borderColor: '#1976d2',
                    },
                }}
            >
                {text}
            </Button>
        </Tooltip>
    );
};

export default AddCustomButton; 