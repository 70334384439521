import React, { useState } from 'react'
import {
    Fab,
    Zoom,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import CloseIcon from '@mui/icons-material/Close'

import ComingSoonPopup from '../../ComingSoon/ComingSoonPopup'

const ChatBotIcon = () => {
    const [open, setOpen] = useState(false)
    const { showComingSoon } = ComingSoonPopup({
        message:
            "Excited to announce that we're rolling out our AI assistant soon! 🎉", // optional
    })

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleMagicHappen = () => {
        showComingSoon()
    }

    return (
        <>
            <Zoom in={true} timeout={500} style={{ transitionDelay: '500ms' }}>
                <Fab
                    color="primary"
                    aria-label="chat"
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        zIndex: 1000,
                    }}
                    onClick={handleMagicHappen} //{handleOpen}
                >
                    <ChatIcon />
                </Fab>
            </Zoom>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="chat-dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="chat-dialog-title">
                    Chat with AI Assistant
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Add your chatbot component here */}
                    <p>This is where your chatbot interface would go.</p>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ChatBotIcon
