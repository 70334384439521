import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Container,
    Box,
    Divider,
    Stepper, 
    Step,
    StepLabel,
    TextField,
    Button,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import InfoIcon from '@mui/icons-material/Info'
import { UserContext } from '../contexts/UserContext'
import ComingSoonPopup from '../components/ComingSoon/ComingSoonPopup'
import { getBackendUserUrl } from '../utils'
import axios from 'axios'
import EventCard from '../components/myEvents/Host/EventCard'
import RecommendIcon from '@mui/icons-material/Recommend'
import { toast } from 'react-toastify'
import EventPlannerForm from '../components/EventForm/EventPlannerForm'


const EventRentStartPage = () => {
  const { user, setUser, hasRole } = useContext(UserContext)

    const [activeStep, setActiveStep] = useState(0)
    const [formData, setFormData] = useState({
        name: '',
        eventType: [],
        location: '',
        guests: '',
        budget: '',
        additionalInfo: '',
    })
    const [showForm, setShowForm] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [recommendedEvents, setRecommendedEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [additionalRecommendations, setAdditionalRecommendations] = useState(null)

    // Show beta message if redirected from a protected route
    const betaMessage = location.state?.message

    // Determine if user has beta access
    const hasBetaAccess = user && hasRole(['user', 'admin'])

    const { showComingSoon } = ComingSoonPopup({
        message:
            "Excited to announce that we're rolling out our AI event planner! 🎉", // optional
    })

    const handleMagicHappen = () => {
        // Check if user has admin role
        if (hasBetaAccess) {
            setShowForm(true);
        } else {
            showComingSoon();
        }
    }

    const handleDoItMyself = () => {
        history.push(`/marketplace`)
    }

    // const getEventRecommendations = async (response) => {
    //   if (response.data.success) {
    //     console.log('AI Response:', response.data);
    //   }
      
    //   if (response.data && response.data.event_recommendations) {

    //     // for /openai/generate-event-vector-search endpoint 
    //     // Transform the data to match your EventCard props
    //     const formattedEvents = response.data.event_recommendations.map(event => ({
    //         eventId: event.event_template.id || 'template',
    //         venueId: event.venue.id,
    //         title: event.event_template.title,
    //         venueTitle: event.venue.name,
    //         guestCount: event.event_template.guest_count,
    //         eventStartDate: new Date().toISOString(), // placeholder date
    //         eventEndDate: new Date().toISOString(), // placeholder date
    //         firstImage: event.venue.image_url || '/default-event-image.jpg',
    //         // Add any other required props that your EventCard expects
    //         description: event.event_template.description,
    //         theme: event.event_template.theme,
    //         estimatedCost: event.estimated_cost,
    //         addons: event.addons,
    //         sponsors: event.sponsors
    //     }));
        
    //     setRecommendedEvents(formattedEvents);
        
    //     // Store additional recommendations
    //     if (response.data.additional_recommendations) {
    //         setAdditionalRecommendations(response.data.additional_recommendations);
    //     }
    //   } else {
    //     toast.error('No similar events found');
    //   }
    // }

    const getVenueRecommendations = async (response) => {
      if (response.data && response.data.event_recommendations) {
        console.log('getVenueRecommendations')
        console.log('response', response.data.event_recommendations)
        setRecommendedEvents(response.data.event_recommendations);
        setAdditionalRecommendations(response.data.additional_recommendations);
        
        // Save to localStorage
        localStorage.setItem('eventRecommendations', JSON.stringify({
          events: response.data.event_recommendations,
          additional: response.data.additional_recommendations
        }));
      } else {
        toast.error('No similar events found');
      }
    }
    
  const handleSubmitForm = async (formData) => {
    setIsLoading(true);
    try {
      // Validate form data
      if (!formData.eventType.length) {
        toast.error('Please select at least one event type');
        setIsLoading(false);
        return;
      }
      
      if (!formData.name || !formData.location || !formData.guests || !formData.budget) {
        toast.error('Please fill in all required fields');
        setIsLoading(false);
        return;
      }

      if (!formData.startDate || !formData.endDate) {
        toast.error('Please select event dates');
        setIsLoading(false);
        return;
      }
      console.log('formData', formData)
      const prompt = `Generate an event plan for a ${formData.eventType.join(', ')} event with the following details:
        - Host: ${formData.name}
        - Location: ${formData.location}
        - Number of guests: ${formData.guests}
        - Budget: $${formData.budget}
        - Preferred Dates: ${formData.startDate.format('MMMM D, YYYY')} to ${formData.endDate.format('MMMM D, YYYY')}
        - Additional requirements: ${formData.additionalInfo || 'None provided'}

        Please make sure to align the strategy for organizing the event with the suggested venues, as well as aligning the budget with the suggested sponsors and vendors.
      `;

      console.log('Sending prompt:', prompt);
      console.log('Form data:', formData);

      const response = await axios.post(
        getBackendUserUrl() + '/openai_venues/recommend-venues',
        {
          prompt: prompt,
          // guest_count: parseInt(formData.guests, 10),
          // budget: parseInt(formData.budget, 10),
          // location: formData.location,
          // event_type: formData.eventType[0],
          // date_range: {
          //   start: formData.startDate.format('YYYY-MM-DD'),
          //   end: formData.endDate.format('YYYY-MM-DD')
          // }
        }
      );
      // TODO: Review this code and options as its the two ways to get user event recommendations
      // const response = await axios.post(getBackendUserUrl() + `/openai/generate-event-vector-search`, {
      //   prompt: prompt
      // });
      // await getEventRecommendations(prompt);
      // const response = await axios.post(getBackendUserUrl() + `/openai_venues/recommend-venues`, {
      //   prompt: prompt
      // });
      await getVenueRecommendations(response);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate recommendations');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventSelection = async (event) => {
    console.log('venueId', event.venueId)
    console.log('event', '/venue/venue/' + event.venueId)

    const response = await axios.get(getBackendUserUrl() + '/venue/venue/' + event.venueId);
    const venueData = response.data;
    console.log('response', response)

    // TODO: utilize the recommendations and addons for the event in the shopping cart
    
    // Format the event data to match the shopping cart expectations
    const cartData = {
        venue: {
          ...venueData
            // venueId: event.venueId, // Remove template prefix
            // title: event.venueTitle,
            // startPrice: event.estimatedCost.venue,
            // images: [{ url: event.firstImage }],
            // description: event.description,
            // capacity: {
            //     seated: event.guestCount,
            //     standing: event.guestCount * 1.5 // Estimate if not provided
            // },
            // // Add any other venue properties that shopping cart might need
            // eventType: event.titcle.split(' at ')[0], // Extract event type from title
            // city: event.venueTitle.split(' ').pop(), // Extract city if available
        },
        cartAddons: event.addons.map(addon => ({
            addonId: addon.addonId,
            companyName: addon.companyName,
            addonType: addon.addonType,
            description: addon.description,
            price: addon.price || 0,
            count: addon.count || 1,
            company: addon.company,
            selected: true // Pre-select recommended addons
        })),
        currGuestCount: event.guestCount,
        sponsors: event.sponsors.map(sponsor => ({
            sponsorId: sponsor.sponsorId,
            companyName: sponsor.companyName,
            addonType: sponsor.addonType,
            description: sponsor.description,
            price: sponsor.price || 0,
            count: sponsor.count || 1,
            company: sponsor.company
        })),
        isTemplate: true,
        templateData: {
            ...event,
            eventStartDate: new Date(event.eventStartDate),
            eventEndDate: new Date(event.eventEndDate)
        }
    };

    history.push({
        pathname: '/shoppingcart',
        state: cartData
    });
  };

  useEffect(() => {
    const savedRecommendations = localStorage.getItem('eventRecommendations');
    if (savedRecommendations) {
      const { events, additional } = JSON.parse(savedRecommendations);
      setRecommendedEvents(events);
      setAdditionalRecommendations(additional);
    }
  }, []);

  return (
    <Box sx={{ 
      minHeight: '100vh',
      pt: { xs: 2, sm: 3, md: 4 }, // Responsive top padding
      pb: 8 // Bottom padding for recommendations
    }}>
      {betaMessage && !hasBetaAccess && (
        <Box sx={{
          bgcolor: 'info.light',
          p: 2,
          mb: 2,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography color="info.contrastText">
            {betaMessage}
          </Typography>
        </Box>
      )}

      <Grid container spacing={0} sx={{ 
        minHeight: { xs: 'auto', md: 'calc(100vh - 250px)' }, // Adjusted height
        mb: 4 // Margin bottom for spacing
      }}>
        {/* Left Side Content */}
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#1976d2',
          color: '#fff',
          p: { xs: 3, sm: 4, md: 6 }, // Responsive padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: { xs: '0', md: '0 16px 16px 0' } // Rounded corners on desktop
        }}>
          <Typography variant="h4" gutterBottom sx={{ 
            mt: 2,
            textAlign: 'center',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' } // Responsive font size
          }}>
            Effortlessly Plan, Manage, and Elevate Your Events
          </Typography>
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            p: { xs: 2, sm: 3, md: 4 } // Responsive padding
          }}>
            <Card sx={{
              backgroundColor: 'transparent',
              color: '#fff',
              boxShadow: 'none',
              width: '100%',
              maxWidth: 500 // Maximum width for the image container
            }}>
              <CardContent>
                <img
                  src="https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18:28:05.840Z"
                  alt="EventRent"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '400px',
                    objectFit: 'contain'
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(0px)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(10px)')}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Right Side Content */}
        <Grid item xs={12} md={7} sx={{
          p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          bgcolor: 'background.paper'
        }}>
          {!showForm ? (
            <Box textAlign="center">
              <Typography variant="h4" gutterBottom>
                Let's plan your event 🚀
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 4 }}>
                We promise it'll only take a few moments to create. Choose an option below.
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                <Grid item>
                  <Button variant="outlined" sx={{
                    padding: 2,
                    width: 250,
                    height: 150,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={handleDoItMyself}>
                    <Typography variant="h6" sx={{
                      marginBottom: 1,
                      textAlign: 'center'
                    }}>
                      Create From Scratch
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                      Create your event from scratch, giving you full control over every detail.
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip 
                    title={!hasBetaAccess ? "Coming soon! This feature is currently in beta." : ""}
                    arrow
                  >
                    <Button 
                      variant="outlined" 
                      sx={{
                        padding: 2,
                        width: 250,
                        height: 150,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        '&:hover': {
                          backgroundColor: 'rgba(25, 118, 210, 0.04)',
                        },
                        // Add a subtle beta indicator for non-admin users
                        '&::after': !hasBetaAccess ? { // !hasRole(['admin', 'user']) ? {
                          content: '"BETA"',
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          fontSize: '0.75rem',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          backgroundColor: 'primary.main',
                          color: 'white',
                          opacity: 0.8,
                        } : {}
                      }} 
                      onClick={handleMagicHappen}
                    >
                      <Typography variant="h6" sx={{
                        marginBottom: 1,
                        textAlign: 'center'
                      }}>
                        Plan with AI
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        Answer a few questions and let our AI assistant generate an event template for you!
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <EventPlannerForm 
              onSubmit={handleSubmitForm}
              initialData={formData}
            />
          )}
        </Grid>
      </Grid>

      {/* Results Section */}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
        {(isLoading || recommendedEvents.length > 0) && (
          <>
            <Divider sx={{ my: 6 }} />
            
            <Box sx={{ mb: 5 }}>
              <Typography 
                variant="h4" 
                component="h2" 
                sx={{ 
                  mb: 1,
                  fontWeight: 600,
                }}
              >
                Event Recommendations
              </Typography>
              <Typography 
                variant="subtitle1" 
                color="text.secondary"
                sx={{ mb: 4 }}
              >
                Based on your requirements, here are some suggested event templates
              </Typography>

              {isLoading ? (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  minHeight: '200px'
                }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {/* Event Cards Grid */}
                  {recommendedEvents.length > 0 && (
                    <Grid container spacing={3} sx={{ mb: 6 }}>
                      {recommendedEvents.map((event, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <EventCard
                            event={event}
                            onClick={() => handleEventSelection(event)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Additional Recommendations Section */}
                  {additionalRecommendations && (
                    <Box 
                      sx={{ 
                        mt: 4,
                        p: 4,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 1
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        mb: 3
                      }}>
                        <RecommendIcon 
                          color="primary" 
                          sx={{ mr: 2, fontSize: 28 }} 
                        />
                        <Typography variant="h5" component="h3">
                          Planning Recommendations
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        pl: 2,
                        borderLeft: (theme) => 
                          `4px solid ${theme.palette.primary.main}`
                      }}>
                        {additionalRecommendations.split('\n')
                          .filter(line => line.trim())
                          .map((line, i) => (
                            <Typography 
                              key={i} 
                              variant="body1" 
                              sx={{ 
                                mb: 2,
                                color: 'text.primary',
                                '&:last-child': { mb: 0 }
                              }}
                            >
                              {line}
                            </Typography>
                          ))}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Container>

      {isLoading && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mt: 4,
          mb: 4
        }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default EventRentStartPage
