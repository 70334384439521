import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Chip,
  Divider,
  InputAdornment,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const EventPlannerForm = ({ onSubmit, initialData }) => {
  // Initialize form data with proper date objects
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem('eventPlannerForm');
    if (savedData) {
      const parsed = JSON.parse(savedData);
      return {
        ...parsed,
        startDate: parsed.startDate ? dayjs(parsed.startDate) : null,
        endDate: parsed.endDate ? dayjs(parsed.endDate) : null,
      };
    }
    return {
      name: '',
      eventType: [],
      location: '',
      guests: '',
      budget: '',
      additionalInfo: '',
      startDate: null,
      endDate: null
    };
  });

  // Save to localStorage with dates converted to ISO strings
  useEffect(() => {
    const dataToSave = {
      ...formData,
      startDate: formData.startDate ? formData.startDate.toISOString() : null,
      endDate: formData.endDate ? formData.endDate.toISOString() : null,
    };
    localStorage.setItem('eventPlannerForm', JSON.stringify(dataToSave));
  }, [formData]);

  const eventCategories = {
    Interactive: ['Fitness', 'Hackathon', 'Team Building', 'Workshop', 'Other'],
    Professional: [
        'Conference',
        'Corporate Event',
        'Networking Event',
        'Product Launch',
        'Seminar/Panel',
        'Trade Show',
        'Other'
    ],
    Social: [
        'Bar',
        'Birthday',
        'Brunch',
        'Dinner',
        'Happy Hour',
        'Holiday Party',
        'Party',
        'Other'
    ],
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleEventTypeChange = (type) => {
    setFormData(prev => ({
      ...prev,
      eventType: prev.eventType.includes(type)
        ? prev.eventType.filter(t => t !== type)
        : [...prev.eventType, type]
    }));
  };

  const handleDateChange = (type, newValue) => {
    setFormData(prev => ({
      ...prev,
      [type]: newValue
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={0} sx={{ p: 4, borderRadius: 2, bgcolor: 'background.default' }}>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{ 
                  fontWeight: 600,
                  fontSize: '1.25rem',
                  color: 'text.primary',
                  mb: 3
                }}
              >
                Event Details
              </Typography>
              <TextField
                fullWidth
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                sx={{ 
                  mb: 3,
                  '& .MuiInputLabel-root': {
                    fontSize: '0.95rem'
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '1rem'
                  }
                }}
              />
              
              {/* Date/Time Pickers */}
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: 2,
                    fontWeight: 500,
                    fontSize: '0.95rem'
                  }}
                >
                  Preferred Event Dates
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Start Date"
                      value={formData.startDate}
                      onChange={(newValue) => handleDateChange('startDate', newValue)}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          size: "medium",
                          sx: { 
                            '& .MuiInputLabel-root': {
                              fontSize: '0.95rem'
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="End Date"
                      value={formData.endDate}
                      onChange={(newValue) => handleDateChange('endDate', newValue)}
                      minDate={formData.startDate || null}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          size: "medium",
                          sx: { 
                            '& .MuiInputLabel-root': {
                              fontSize: '0.95rem'
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <TextField
                fullWidth
                name="location"
                label="Event Location"
                variant="outlined"
                value={formData.location}
                onChange={handleChange}
                sx={{ mb: 3 }}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="guests"
                    label="Number of Guests"
                    type="number"
                    variant="outlined"
                    value={formData.guests}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="budget"
                    label="Budget"
                    variant="outlined"
                    value={formData.budget}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Event Type
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
                {Object.entries(eventCategories).map(([category, types]) => (
                  <Box key={category} sx={{ width: '100%', mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                      {category}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {types.map((type) => (
                        <Chip
                          key={type}
                          label={type}
                          onClick={() => handleEventTypeChange(type)}
                          color={formData.eventType.includes(type) ? "primary" : "default"}
                          sx={{ 
                            borderRadius: '8px',
                            '&:hover': {
                              backgroundColor: formData.eventType.includes(type) 
                                ? 'primary.main' 
                                : 'action.hover'
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Full Width Additional Info */}
          <Grid item xs={12}>
            <Box sx={{ mb: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                  Additional Information
                </Typography>
                <Tooltip title="Help us understand your vision better. What's the mood, theme, or any special requirements?">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="additionalInfo"
                placeholder="Tell us more about your event vision..."
                variant="outlined"
                value={formData.additionalInfo}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    '& textarea': {
                      resize: 'vertical',
                    },
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => onSubmit(formData)}
              startIcon={<AutoAwesomeIcon />}
              sx={{
                py: 2,
                borderRadius: 2,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                }
              }}
            >
              Generate Event Recommendations
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};

export default EventPlannerForm; 