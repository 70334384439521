import React from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material'
import PushPinIcon from '@mui/icons-material/PushPin'
import PersonIcon from '@mui/icons-material/Person'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const VendorCard = ({
    eventAddon,
    onClick,
    packageDetails,
    handleOpenMessages,
    removeVendor,
}) => {
    const getStatusIcon = (status) => {
        switch (status) {
            case 'confirmed':
                return (
                    <CheckCircleIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'blue' }}
                    />
                )
            case 'pending':
            case 'moreInfo':
                return (
                    <WarningIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'blue' }}
                    />
                )
            default:
                return null // No icon shown for unknown status
        }
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                '&:hover .delete-button': {
                    opacity: 0,
                },
            }}
            onClick={onClick}
        >
            <CardMedia
                component="img"
                height="140"
                image={
                    eventAddon.company.logoUrl ??
                    'https://picsum.photos/200/300'
                }
                alt={eventAddon.company.name}
            />
            <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                    {eventAddon.company.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PushPinIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {eventAddon.company.desc ||
                            'Company description not available'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocalOfferIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        Package Name:{' '}
                        {packageDetails.name || 'Package info not available'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <AttachMoneyIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        Package Cost:{' '}
                        {packageDetails.cost || 'No cost details available'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    {getStatusIcon(eventAddon.status)}
                    <Typography variant="body2" color="text.secondary">
                        Status: {eventAddon.status || 'Status not available'}
                    </Typography>
                </Box>
            </CardContent>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    display: 'flex',
                    gap: 1,
                }}
            >
                <Tooltip title="Open messages" arrow>
                    <IconButton
                        sx={{
                            color: 'blue',
                            minWidth: 'auto',
                            p: 1,
                        }}
                        onClick={() => handleOpenMessages(eventAddon)}
                    >
                        <MailOutlineIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete vendor" arrow>
                    <IconButton
                        sx={{
                            color: 'red',
                            minWidth: 'auto',
                            p: 1,
                        }}
                        onClick={() => removeVendor(eventAddon)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Card>
    )
}

export default VendorCard
