import React from 'react';
import { Box, TextField, Paper } from '@mui/material';
import CollapsibleSection from 'components/common/CollapsibleSection';

const EventFormatSection = ({ 
    eventData, 
    handleChange, 
    isEditing, 
    onEdit, 
    onSave,
    eventFormatRef 
}) => {
    // Check if required fields are filled
    const isComplete = Boolean(
        eventData.event?.format?.agenda &&
        eventData.event?.format?.keyActivities?.length > 0 &&
        eventData.event?.format?.specialFeatures?.length > 0
    );

    return (
        <CollapsibleSection
            title="Event Format"
            isComplete={isComplete}
            isEditing={isEditing}
            onEdit={onEdit}
            onSave={onSave}
        >
                <TextField
                    fullWidth
                    margin="normal"
                    label="Agenda Overview"
                    value={eventData.event?.format?.agenda || ''}
                    onChange={(e) =>
                        handleChange(
                            'event',
                            'format.agenda',
                            e.target.value
                        )
                    }
                    disabled={!isEditing}
                    multiline
                    rows={2}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Key Activities"
                    value={
                        eventData.event?.format?._raw?.keyActivities ||
                        eventData.event?.format?.keyActivities?.join(', ') ||
                        ''
                    }
                    onChange={(e) =>
                        handleChange(
                            'event',
                            'format.keyActivities',
                            e.target.value
                        )
                    }
                    disabled={!isEditing}
                    multiline
                    rows={2}
                    helperText="Separate activities with commas"
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Special Features"
                    value={
                        eventData.event?.format?._raw?.specialFeatures ||
                        eventData.event?.format?.specialFeatures?.join(', ') ||
                        ''
                    }
                    onChange={(e) =>
                        handleChange(
                            'event',
                            'format.specialFeatures',
                            e.target.value
                        )
                    }
                    disabled={!isEditing}
                    multiline
                    rows={2}
                    helperText="Separate features with commas"
                />
        </CollapsibleSection>
    );
};

export default EventFormatSection; 