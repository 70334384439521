import React, { useState, useEffect } from 'react'
import {
    Box,
    TextField,
    IconButton,
    Paper,
    Chip,
    Typography,
    Autocomplete,
    Link,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import TagIcon from '@mui/icons-material/LocalOffer'

// Predefined list of taggable locations
const tagOptions = [
    // Event Basics Section
    { label: 'Event Basics', link: 'internal:Event-Basics-section' },
    { label: 'Date and Time', link: 'internal:Event-Basics-section' },
    { label: 'Event Vision', link: 'internal:Event-Basics-section' },
    { label: 'Event Type', link: 'internal:Event-Basics-section' },

    // Audience Section
    { label: 'Target Audience', link: 'internal:audience-section' },
    { label: 'Audience Information', link: 'internal:audience-section' },
    { label: 'Demographics', link: 'internal:audience-section' },

    // Venue Section
    { label: 'Venue', link: 'internal:venue-section' },
    { label: 'Location', link: 'internal:venue-section' },

    // Format Section
    { label: 'Event Format', link: 'internal:format-section' },
    { label: 'Agenda Overview', link: 'internal:format-section' },

    // Speakers Section
    { label: 'Speakers', link: 'internal:speakers-section' },
    { label: 'Speakers and Presenters', link: 'internal:speakers-section' },

    // Marketing Section
    { label: 'Marketing and Promotion', link: 'internal:marketing-section' },
    { label: 'Marketing Strategy', link: 'internal:marketing-section' },

    // Timeline/Agenda Tab
    { label: 'Agenda', link: 'internal:Event-Timeline-section' },
    { label: 'Timeline', link: 'internal:Event-Timeline-section' },

    // Other Sections
    { label: 'Sponsors', link: 'internal:sponsors-section' },
    { label: 'Budget', link: 'internal:budget-section' },
    { label: 'Vendors', link: 'internal:vendors-section' },
]

function MessageInput({ onSendMessage }) {
    const [message, setMessage] = useState('')
    const [tags, setTags] = useState([])
    const [suggestedTags, setSuggestedTags] = useState([])

    useEffect(() => {
        // Skip empty messages
        if (!message.trim()) {
            setSuggestedTags([])
            return
        }

        // Analyze message content and suggest tags
        const words = message
            .toLowerCase()
            .split(/\s+/)
            .filter((word) => word.length > 2) // Filter out small words
        const suggested = tagOptions.filter((tag) =>
            // Check if any word in the message is part of the tag label
            words.some(
                (word) =>
                    tag.label.toLowerCase().includes(word) ||
                    // Also check if any word starts with the same characters
                    tag.label
                        .toLowerCase()
                        .split(/\s+/)
                        .some(
                            (tagWord) =>
                                tagWord.startsWith(word) ||
                                word.startsWith(tagWord)
                        )
            )
        )

        setSuggestedTags(suggested)
        console.log('Suggested tags:', suggested)
    }, [message])

    const handleSend = () => {
        console.log('handleSend tags', { message, tags })
        if (message.trim()) {
            onSendMessage({
                message: message.trim(),
                referenceTag: tags.length > 0 ? tags : null, // Send tags directly as array of objects
            })
            setMessage('')
            setTags([])
        }
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
        // Implement inline tagging
        const lastWord = event.target.value.split(' ').pop()
        if (lastWord.startsWith('#')) {
            // Show autocomplete for tags
        }
    }

    const handleTagAdd = (tag) => {
        if (!tags.find((t) => t.label === tag.label)) {
            setTags([...tags, tag])
        }
    }

    const handleTagDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag.label !== tagToDelete.label))
    }

    return (
        <Paper
            elevation={1}
            sx={{ p: 1, borderRadius: 0, borderTop: '1px solid #e0e0e0' }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        fullWidth
                        placeholder="Type your message..."
                        variant="outlined"
                        size="small"
                        value={message}
                        onChange={handleMessageChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault()
                                handleSend()
                            }
                        }}
                    />
                    <IconButton color="primary" onClick={handleSend}>
                        <SendIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {tags.map((tag) => (
                        <Chip
                            key={tag.label}
                            label={tag.label}
                            onDelete={() => handleTagDelete(tag)}
                            size="small"
                            sx={{ mr: 0.5, mb: 0.5 }}
                            component={Link}
                            href={tag.link}
                            clickable
                        />
                    ))}
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography
                        variant="caption"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <TagIcon fontSize="small" sx={{ mr: 0.5 }} />
                        Add Reference Tags:
                    </Typography>
                    <Autocomplete
                        multiple
                        options={suggestedTags}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    size="small"
                                    label={option.label}
                                    {...getTagProps({ index })}
                                    component={Link}
                                    href={option.link}
                                    clickable
                                />
                            ))
                        }
                        value={tags}
                        onChange={(event, newValue) => {
                            setTags(newValue)
                        }}
                        sx={{ mt: 0.5 }}
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default MessageInput
