import React, { useState } from 'react'
import {
    Card,
    CardContent,
    IconButton,
    Typography,
    Box,
    Snackbar,
    Alert,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import GoogleSignIn from './googleLogin'

const LoginCard = ({ onClose, onUserLogin }) => {
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'success',
    })

    const handleLoginSuccess = (result) => {
        console.log('Login success result:', result)
        setNotification({
            open: true,
            message: result.message || 'Operation completed successfully',
            severity: result.success ? 'success' : 'info',
        })

        // Close the modal after the notification has been shown
        setTimeout(() => {
            onClose()
        }, 3000)
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setNotification({ ...notification, open: false })
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1300,
            }}
        >
            <Card
                sx={{
                    width: 400,
                    height: 200,
                    borderRadius: 2,
                    position: 'relative',
                }}
            >
                <CardContent
                    sx={{
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bgcolor: 'background.paper',
                            color: 'text.primary',
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                left: 8,
                                color: 'text.primary',
                            }}
                            aria-label="close"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            Join Happenings Beta
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            pt: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'center',
                            px: 2,
                        }}
                    >
                        <GoogleSignIn onUserLogin={handleLoginSuccess} />
                    </Box>
                </CardContent>
            </Card>

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification.severity}
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default LoginCard
