import React from 'react'
import { Card, CardContent, CardMedia, Typography, Link } from '@mui/material'

const VenueBox = ({ venueData, highlightedSection, venueRef }) => {
    // Safely get the first image URL
    const imageUrl = venueData?.images?.[0]?.url || 'default-image-url.jpg'
    // Format capacity for display
    const formatCapacity = (capacity) => {
        console.log('Capacity:', capacity)
        if (!capacity) return 'Not specified'
        return `Max: ${capacity.standing || 'N/A'} | Seated: ${capacity.seated || 'N/A'} | Standing: ${capacity.standing || 'N/A'}`
    }

    const displayedDescription = `${venueData?.description.substring(0, 200)}...`

    return (
        <Card ref={venueRef}>
            <Typography
                variant="h6"
                component="h2"
                sx={{ ml: 2, mt: 2 }}
                gutterBottom
            >
                Venue
            </Typography>
            <CardMedia
                component="img"
                height="200"
                image={imageUrl}
                alt={venueData?.title || 'Venue Image'}
            />
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    {venueData?.title || 'Venue Name Not Available'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {venueData?.address?.full || 'Address Not Available'} |
                    Capacity: {formatCapacity(venueData?.capacity)}
                </Typography>
                <Typography variant="body2" paragraph sx={{ mt: 2 }}>
                    {displayedDescription || 'No description available'}
                </Typography>
                <Link
                    href={`/venue/${venueData?.id}`} //{venueData?.website || '#'}
                    underline="hover"
                    sx={{
                        transition: 'all 0.3s ease-in-out',
                        ...(highlightedSection === 'venue' && {
                            backgroundColor: 'rgba(25, 118, 210, 0.1)',
                            padding: '4px',
                            borderRadius: '4px',
                        }),
                    }}
                >
                    Visit Venue Page
                </Link>
            </CardContent>
        </Card>
    )
}

export default VenueBox
