import React from 'react'
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material'
import PeopleIcon from '@mui/icons-material/People'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import LocalBarIcon from '@mui/icons-material/LocalBar'

const PackageCard = ({ package: pkg, isSelected, onSelect }) => {
    return (
        <Card
            onClick={onSelect}
            sx={{
                cursor: 'pointer',
                border: isSelected ? '2px solid #1976d2' : 'none',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CardMedia
                component="img"
                height="140"
                image={pkg.image}
                alt={pkg.name}
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                    {pkg.name}
                </Typography>
                <Typography variant="h6" color="text.secondary">
                    ${pkg.price} - Minimum Spend
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                >
                    <PeopleIcon sx={{ mr: 1 }} /> {pkg.capacity}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                >
                    <RestaurantIcon sx={{ mr: 1 }} /> {pkg.food}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                >
                    <LocalBarIcon sx={{ mr: 1 }} /> {pkg.alcohol}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    {pkg.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default PackageCard
