import React from 'react';

const VenueOwnerHomePage = () => {
    return (
        <div className="venue-owner-homepage">
        </div>
    );
};

export default VenueOwnerHomePage;
