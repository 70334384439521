import React, { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    IconButton,
    Collapse,
    Chip,
    Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
}));

const SectionHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const CollapsibleSection = ({
    title,
    isComplete,
    isEditing,
    onEdit,
    onSave,
    children,
    defaultExpanded = false,
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    return (
        <StyledPaper elevation={expanded ? 3 : 1}>
            <SectionHeader onClick={() => setExpanded(!expanded)}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        {title}
                    </Typography>
                    <Chip
                        label={isComplete ? "Complete" : "Incomplete"}
                        color={isComplete ? "success" : "warning"}
                        size="small"
                        sx={{ 
                            height: '24px',
                            '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.75rem',
                                fontWeight: 500
                            }
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            isEditing ? onSave() : onEdit();
                        }}
                    >
                        {isEditing ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
            </SectionHeader>
            <Collapse in={expanded}>
                <Divider />
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            </Collapse>
        </StyledPaper>
    );
};

export default CollapsibleSection; 