const configs = {
    production: {
        backendUserUrl: 'https://happenings.dev',
        testAccounts: null,
        featureFlags: {
            enableTestMode: false,
        },
        GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENTID_LOGIN,
        API_URL: process.env.REACT_APP_API_URL,
        MJ_APIKEY_PUBLIC: process.env.MJ_APIKEY_PUBLIC,
        MJ_APIKEY_PRIVATE: process.env.MJ_APIKEY_PRIVATE,
    },
    development: {
        backendUserUrl: 'http://127.0.0.1:8000',
        testAccounts: {
            vendor: {
                userId: '56555d60-ee76-4520-becf-5d28f2fb9484',
                email: 'eliot.testing.brown@gmail.com',
                firstName: 'John',
                lastName: 'Testing',
                type: 'Vendor',
                role: 'test_user',
            },
            customer: 'eliot.testing.brown@gmail.com',
            host: {
                userId: 'b1cd19fb-437a-4c6c-b097-577fdd78c930',
                email: 'eventrent.contact@gmail.com',
                firstName: 'Susan',
                lastName: 'Testing',
                type: 'Host',
                role: 'test_user',
            },
            event: {
                eventId: 'cbdf8dcc-8e2f-4d29-9766-db322475c603',
            },
        },
        featureFlags: {
            enableTestMode: true,
        },
        GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENTID_LOGIN,
        API_URL: process.env.REACT_APP_API_URL,
        MJ_APIKEY_PUBLIC: process.env.MJ_APIKEY_PUBLIC,
        MJ_APIKEY_PRIVATE: process.env.MJ_APIKEY_PRIVATE,
    },
    test: {
        backendUserUrl: 'http://127.0.0.1:8000',
        testAccounts: {
            vendor: {
                userId: '56555d60-ee76-4520-becf-5d28f2fb9484',
                email: 'eliot.testing.brown@gmail.com',
                firstName: 'John',
                lastName: 'Testing',
                type: 'Vendor',
                role: 'test_user',
            },
            customer: 'eliot.testing.brown@gmail.com',
            host: {
                userId: 'b1cd19fb-437a-4c6c-b097-577fdd78c930',
                email: 'eventrent.contact@gmail.com',
                firstName: 'Susan',
                lastName: 'Testing',
                type: 'Host',
                role: 'test_user',
            },
            event: {
                eventId: 'cbdf8dcc-8e2f-4d29-9766-db322475c603',
            },
        },
        featureFlags: {
            enableTestMode: true,
        },
        GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENTID_LOGIN,
        API_URL: process.env.REACT_APP_API_URL,
        MJ_APIKEY_PUBLIC: process.env.MJ_APIKEY_PUBLIC,
        MJ_APIKEY_PRIVATE: process.env.MJ_APIKEY_PRIVATE,
    },
}

const env = process.env.NODE_ENV || 'development'
const config = configs[env]

export default config
