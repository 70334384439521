import React, { useState, useEffect } from 'react'

const StripeCheckoutSuccessPage = ({ resetCartCount }) => {
    useEffect(() => {
        // Reset the cart count when this page is loaded
        resetCartCount()
    }, [resetCartCount])

    return (
        <div>
            {/* <Navbar />  */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    Thank you for planning your event with us! An email will be
                    sent to you for confirmation!
                </div>
            </div>
        </div>
    )
}

export default StripeCheckoutSuccessPage
