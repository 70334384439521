import React from 'react';
import { Tabs, Tab } from 'react-bootstrap'; // Assuming you're using react-bootstrap for tabs
import './Styles/VenueOwnerNavBarStyles.css'; // Importing the CSS file for styling
import HamburgerMenuWithAvatar from '../../components/Hamburgermenu';
import logo from '../../assets/EventRent_transparent.png';
import { Box } from '@mui/material';
import MyVenues from 'pages/VenueOwner/MyVenues';

const logoStyle = {
    width: '140px',
    height: 'auto',
    cursor: 'pointer',
};

const tabStyle = {
    height: '50px', // Set the height to match the logo
    display: 'flex',
    alignItems: 'center', // Center the content vertically
    fontSize: '16px', // Adjust font size as needed
};

const VenueOwnerNavBar = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column', // Stack the logo, tabs, and hamburger vertically
                alignItems: 'center',    // Center all items horizontally
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1200,
                bgcolor: 'white', // Optional: Set background color
                paddingTop: '10px',  // Space from the top of the page
            }}
        >
            {/* Logo and Hamburger menu at the top */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    px: 2,
                    mb: 2, // Margin below for spacing between the logo and tabs
                }}
            >

                <Box className="logo-container" sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={logo} alt="EventRent Logo" style={logoStyle} />
                </Box>

                <Box className="hamburger-menu-container" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HamburgerMenuWithAvatar 
                        onUserLogin={() => {}} // Add your login function
                        onUserLogout={() => {}} // Add your logout function
                        userData={{}} // Pass user data if available
                    />
                </Box>
            </Box>

            {/* Tabs in the center, below the logo and hamburger menu */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: '280px', // Add margin-top to create space below the navbar
                }}
            >
                <Tabs defaultActiveKey="myEvents" className="custom-tabs">
                    <Tab eventKey="myEvents" title="MY EVENTS" style={tabStyle}>
                        {/* <MyEvents /> */}
                    </Tab>
                    <Tab eventKey="venues" title="VENUES" style={tabStyle}>
                        <MyVenues />
                    </Tab>
                    <Tab eventKey="pages" title="MESSAGES" style={tabStyle}>
                        {/* <Messages /> */}
                    </Tab>
                    <Tab eventKey="account" title="ACCOUNT" style={tabStyle}>
                        {/* <Account /> */}
                    </Tab>
                </Tabs>
            </Box>
        </Box>
    );
};

export default VenueOwnerNavBar;
