import React from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Button,
    IconButton,
} from '@mui/material'
import PushPinIcon from '@mui/icons-material/PushPin'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// import { TEMPLATE_EVENT_ID } from '../../../constants/events';
import { TEMPLATE_EVENT_ID } from '../../../constants/constant'

dayjs.extend(utc)
dayjs.extend(timezone)

const EventCard = ({ event, onClick, onDelete }) => {
    const handleDelete = (e) => {
        e.stopPropagation();
        if (event.eventId === TEMPLATE_EVENT_ID) {
            return;
        }
        if (onDelete && typeof onDelete === 'function') {
            onDelete(event);
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                '&:hover .delete-button': {
                    opacity: event.eventId === TEMPLATE_EVENT_ID ? 0 : 1,
                },
            }}
            onClick={onClick}
        >
            <CardMedia
                component="img"
                height="140"
                image={event.firstImage}
                alt={event.title}
            />
            <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                    {event.title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PushPinIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {event.venueTitle || 'Venue not available'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PersonIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {event.guestCount || 'Guest count not available'} guests
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarTodayIcon
                        sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                        Start:{' '}
                        {dayjs(event.eventStartDate).format(
                            'MMM D, YYYY h:mm A'
                        )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        End:{' '}
                        {dayjs(event.eventEndDate).format('MMM D, YYYY h:mm A')}
                    </Typography>
                </Box>
            </CardContent>
            {onDelete && event.eventId !== TEMPLATE_EVENT_ID && (
                <Box
                    className="delete-button"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '50%',
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={handleDelete}
                        sx={{ 
                            color: 'error.main',
                            '&:hover': {
                                backgroundColor: 'error.lighter',
                            }
                        }}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
            )}
        </Card>
    )
}

export default EventCard
