import React, { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { getBackendUserUrl } from '../utils'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('authToken')
            if (token) {
                try {
                    // First, try to decode the token
                    const decodedToken = jwtDecode(token)

                    // If decoding succeeds, verify with the backend
                    const apiUrl =
                        getBackendUserUrl() + '/user/get_current_user'
                    console.log('APIURL', apiUrl)
                    const response = await axios.get(apiUrl, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    setUser(response.data)
                    console.log('USER', response.data)
                } catch (error) {
                    console.error('Error fetching user data:', error)
                    localStorage.removeItem('authToken')
                    setUser(null)
                }
            } else {
                setUser(null)
            }
            setLoading(false)
        }

        fetchUser()
    }, [])

    const login = (userData, token) => {
        if (!userData.role) {
            console.warn('User role not provided in login data')
        }
        localStorage.setItem('authToken', token)
        setUser(userData)
    }

    const logout = () => {
        localStorage.removeItem('authToken')
        setUser(null)
    }

    const hasRole = (requiredRoles) => {
        if (!user || !user.role) return false
        if (!requiredRoles || requiredRoles.length === 0) return true

        // Check if user has any of the required roles
        return requiredRoles.some((role) => user.role.includes(role))
    }

    // You might also want a hasAllRoles function if you need to check for multiple required roles
    const hasAllRoles = (requiredRoles) => {
        if (!user || !user.role) return false
        if (!requiredRoles || requiredRoles.length === 0) return true

        // Check if user has all of the required roles
        return requiredRoles.every((role) => user.role.includes(role))
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                login,
                logout,
                loading,
                hasRole,
                hasAllRoles,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext)
