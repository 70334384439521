import React, { useState, useEffect, useRef } from 'react'
import Card from './Card'
import './CardRow.css'

const CardRow = ({ cards, onQuickAdd }) => {
    const [startIndex, setStartIndex] = useState(0)
    const [cardsPerPage, setCardsPerPage] = useState(3)
    const containerRef = useRef(null)

    useEffect(() => {
        const updateCardsPerPage = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth
                const cardWidth = 200 // Assume each card is 200px wide
                const newCardsPerPage = Math.floor(containerWidth / cardWidth)
                setCardsPerPage(newCardsPerPage)
            }
        }

        updateCardsPerPage()
        window.addEventListener('resize', updateCardsPerPage)

        return () => {
            window.removeEventListener('resize', updateCardsPerPage)
        }
    }, [])

    const nextPage = () => {
        if (startIndex + cardsPerPage < cards.length) {
            setStartIndex(startIndex + cardsPerPage)
        }
    }

    const prevPage = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - cardsPerPage)
        }
    }

    const visibleCards = cards.slice(startIndex, startIndex + cardsPerPage)

    return (
        <div className="card-row" ref={containerRef}>
            <button onClick={prevPage} disabled={startIndex === 0}>
                &lt;
            </button>
            <div className="cards">
                {visibleCards.map((card, index) => (
                    <Card
                        key={index}
                        // addonId={card}
                        companyCard={card}
                        addonType={card.addonType}
                        desc={card.desc}
                        count={card.count}
                        onQuickAdd={onQuickAdd}
                    />
                ))}
            </div>
            <button
                onClick={nextPage}
                disabled={startIndex + cardsPerPage >= cards.length}
            >
                &gt;
            </button>
        </div>
    )
}

export default CardRow
