import React from 'react';
import {
    Modal,
    Box,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const AddSponsorModal = ({ sponsors, open, onClose, addSponsor }) => {

    const handleSponsorClick = (sponsor) => {
        addSponsor(sponsor);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    padding: 4,
                    maxWidth: '90%',
                    maxHeight: '80vh',
                    margin: '0 auto',
                    marginTop: '5%',
                    backgroundColor: 'white',
                    borderRadius: 3,
                    boxShadow: 24,
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" component="h2" gutterBottom>
                    Add Sponsors
                </Typography>

                {sponsors.length === 0 ? (
                    <Typography variant="h6" color="text.secondary" align="center">
                        No sponsors available
                    </Typography>
                ) : (
                    <Grid container spacing={2}>
                        {sponsors.map((sponsor) => (
                            <Grid item xs={12} sm={6} md={4} key={sponsor.id}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        boxShadow: 3,
                                        borderRadius: 2,
                                        '&:hover': {
                                            boxShadow: 6,
                                            cursor: 'pointer',
                                        },
                                        transition: 'box-shadow 0.3s ease',
                                    }}
                                    onClick={() => handleSponsorClick(sponsor)}
                                >
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image={sponsor.company.logoUrl ?? "https://picsum.photos/200/300"}
                                        alt={sponsor.company.name}
                                    />
                                    <CardContent sx={{ paddingBottom: 2 }}>
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            gutterBottom
                                            sx={{ fontWeight: 600 }}
                                        >
                                            {sponsor.company.name}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <PushPinIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {sponsor.company.desc || 'No description available'}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <CategoryIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }} />
                                            <Typography variant="body2" color="text.secondary">
                                                Type: {sponsor.addonType || 'Type not specified'}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <LocationOnIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }} />
                                            <Typography variant="body2" color="text.secondary">
                                                Venue: {sponsor.venueId || 'Venue not available'}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};

export default AddSponsorModal;
