import React, { useState } from 'react'
import { Box, Paper, Typography, IconButton, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Minimize'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import UtteranceList from './UtteranceList'

const AddonMessagePanel = ({
    open,
    onClose,
    conversation,
    onTagClick,
    sender,
    receiver,
    eventId,
}) => {
    console.log('AddonMessagePanel Conversation', conversation)
    console.log('AddonMessagePanel Sender', sender)
    console.log('AddonMessagePanel Receiver', receiver)

    const [isMinimized, setIsMinimized] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const handleMinimize = () => setIsMinimized(true)
    const handleMaximize = () => setIsMinimized(false)
    const handleFullScreen = () => setIsFullScreen(!isFullScreen)

    const renderContent = () => {
        if (!conversation) {
            return (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        textAlign: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1 1 auto',
                                minHeight: 0,
                                overflow: 'hidden',
                            }}
                        >
                            <UtteranceList
                                conversation={conversation}
                                onTagClick={onTagClick}
                                sender={sender}
                                receiver={receiver}
                                eventId={eventId}
                            />
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1 1 auto',
                        minHeight: 0,
                        overflow: 'hidden',
                    }}
                >
                    <UtteranceList
                        conversation={conversation}
                        onTagClick={onTagClick}
                        sender={sender}
                        receiver={receiver}
                        eventId={eventId}
                    />
                </Box>
            </Box>
        )
    }

    return (
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <Paper
                elevation={3}
                sx={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: isMinimized
                        ? '40px'
                        : isFullScreen
                          ? '100%'
                          : '400px',
                    height: '100%',
                    zIndex: 1200,
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'all 0.3s ease-in-out',
                    overflow: 'hidden',
                }}
            >
                {isMinimized ? (
                    <IconButton
                        onClick={handleMaximize}
                        sx={{ height: '100%' }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            {/* Top header with controls */}
                            <Paper
                                elevation={1}
                                sx={{
                                    borderRadius: 0,
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 2,
                                        py: 1.5,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h6" noWrap>
                                        {conversation?.participants[0]
                                            ?.company ||
                                            receiver?.user?.companyAffiliation}
                                    </Typography>
                                    <Box>
                                        <IconButton
                                            onClick={handleFullScreen}
                                            size="small"
                                        >
                                            {isFullScreen ? (
                                                <FullscreenExitIcon />
                                            ) : (
                                                <FullscreenIcon />
                                            )}
                                        </IconButton>
                                        <IconButton
                                            onClick={handleMinimize}
                                            size="small"
                                        >
                                            <MinimizeIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={onClose}
                                            size="small"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Paper>

                            <Box
                                sx={{
                                    flex: '1 1 auto',
                                    minHeight: 0,
                                    overflow: 'hidden',
                                }}
                            >
                                <UtteranceList
                                    conversation={conversation}
                                    onTagClick={onTagClick}
                                    sender={sender}
                                    receiver={receiver}
                                    eventId={eventId}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Paper>
        </Slide>
    )
}

export default AddonMessagePanel
