import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { ChevronDown } from 'lucide-react'
import logo from '../../assets/happeings_logo.png'
import '../../styles/landing.css'
import EmailIcon from '@mui/icons-material/Email'
import { Container, Paper, Typography, Box } from '@mui/material'

const Contact = () => {
    const [activeDropdown, setActiveDropdown] = useState(null)

    const solutionsItem = { label: 'Solutions' }
    const navItems = [
        { label: 'Pricing', path: '/pricing' },
        { label: 'Contact', path: '/contact' },
    ]

    const navItemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
    }

    const handleMouseOver = (index) => setActiveDropdown(index)
    const handleMouseOut = () => setActiveDropdown(null)

    return (
        <div className="landing-page">
            <nav className="nav">
                <motion.div
                    variants={navItemVariants}
                    initial="hidden"
                    animate="visible"
                    className="logo"
                    style={{ marginLeft: '8%' }}
                >
                    <Link to="/" className="navbar-brand">
                        {/* <img 
                            src={logo} 
                            alt="Logo" 
                            style={{ 
                                height: '70px', 
                                width: 'auto',
                                marginRight: '10px'
                            }} 
                        /> */}
                        happenings
                    </Link>
                </motion.div>

                <div className="nav-items">
                    <motion.div
                        key="solutions"
                        variants={navItemVariants}
                        initial="hidden"
                        animate="visible"
                        className="nav-item"
                        onMouseOver={() => handleMouseOver('solutions')}
                        onMouseOut={handleMouseOut}
                    >
                        <Link to="#" className="nav-button">
                            {solutionsItem.label}
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{
                                    rotateX:
                                        activeDropdown === 'solutions'
                                            ? 180
                                            : 0,
                                }}
                                transition={{ duration: 0.2 }}
                                style={{
                                    marginLeft: '0.5rem',
                                    transformOrigin: 'center',
                                }}
                            >
                                <ChevronDown className="w-3 h-3" />
                            </motion.div>
                        </Link>
                    </motion.div>

                    {navItems.map((item, index) => (
                        <motion.div
                            key={index}
                            variants={navItemVariants}
                            initial="hidden"
                            animate="visible"
                            className="nav-item"
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                        >
                            <Link to={item.path} className="nav-button">
                                {item.label}
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </nav>

            <div className="main-content" style={{ marginTop: '100px' }}>
                <Container maxWidth="md" style={{ marginTop: '100px', padding: '2rem' }}>
                    <Box
                        sx={{
                            padding: '2rem',
                            textAlign: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Light transparent background
                            borderRadius: '8px',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Subtle shadow
                            backdropFilter: 'blur(10px)', // Blur effect for depth
                        }}
                    >
                        <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
                            Contact Us
                        </Typography>
                        <Typography variant="body1" paragraph style={{ color: 'white' }}>
                            Please{' '}
                            <Link to="/" style={{ color: '#2196f3', textDecoration: 'underline' }}>
                                sign up for our beta
                            </Link>{' '}
                            to get access to our platform.
                        </Typography>
                        <Typography variant="body1" paragraph style={{ color: 'white' }}>
                            If you want to partner with us directly, please contact us at
                        </Typography>
                        <Typography variant="body1" style={{ color: 'white' }}>
                            <EmailIcon fontSize="small" />{' '}
                            <a
                                href="mailto:eventrent.contact@gmail.com"
                                style={{
                                    color: '#2196f3',
                                    textDecoration: 'underline',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                eventrent.contact@gmail.com
                            </a>
                        </Typography>
                    </Box>
                </Container>
            </div>
        </div>
    )
}

export default Contact
