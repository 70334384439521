import React, { useState, useEffect } from 'react'
import {
    Modal,
    Box,
    Typography,
    Button,
    Chip,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    ListItemText,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import PackageCard from './PackageCard'
import EventIcon from '@mui/icons-material/Event'

// Import images
const cateringServiceImage = require('../../assets/Tastings_Catering_Service.png')
const cateringWineImage = require('../../assets/Tastings_Catering_Wine.png')
const cateringPlatedDinnerImage = require('../../assets/Tastings_Catering_Dinner.png')
const cateringCurationImage = require('../../assets/Tastings_Catering_Curation.png')

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
}

const cateringPackages = [
    {
        id: 1,
        name: 'Basic Appetizers',
        image: cateringWineImage,
        price: 1500,
        capacity: '30 - 70',
        food: 'Selection of assorted appetizers',
        alcohol: 'Wine, procecco, and beer',
        description: 'A simple yet elegant option for smaller gatherings.',
        recommended: false,
        recommendationReason:
            'For events with 30-70 guests, we recommend the Basic Appetizers for a cost-effective yet elegant experience.',
    },
    {
        id: 2,
        name: 'Extended Appetizers',
        image: cateringServiceImage,
        price: 3000,
        capacity: '80 - 160',
        food: 'Selection of assorted appetizers',
        alcohol: 'Wine, procecco, and beer',
        description: 'A simple yet elegant option for medium-sized gatherings.',
        recommended: true,
        recommendationReason:
            'For events with 80-160 guests, consider the Extended Appetizers for a more comprehensive offering.',
    },
    {
        id: 3,
        name: 'Deluxe Cocktail Party',
        image: cateringCurationImage,
        price: 5000,
        capacity: '100 - 200',
        food: "Extensive hors d'oeuvres and food stations",
        alcohol: 'Premium open bar with signature cocktails',
        description: 'Perfect for large networking events or celebrations.',
        recommended: false,
        recommendationReason:
            'For larger events (100-200 guests), the Deluxe Cocktail Party offers a vibrant atmosphere with premium offerings.',
    },
    {
        id: 4,
        name: 'Premium Plated Dinner',
        image: cateringPlatedDinnerImage,
        price: 12000,
        capacity: '50 - 100',
        food: 'Gourmet 3-course meal with vegetarian options',
        alcohol: 'Full bar service',
        description: 'An upscale dining experience for medium-sized events.',
        recommended: false,
        recommendationReason:
            'For a more formal event with 50-100 guests, the Premium Plated Dinner provides an upscale dining experience.',
    },
    {
        id: 5,
        name: 'Customizable Package',
        image: cateringCurationImage, // Use a placeholder image or a specific one for customizable package
        price: 0,
        capacity: 'Custom',
        food: 'Custom',
        alcohol: 'Custom',
        description: 'Create your own custom catering package.',
        recommended: false,
        recommendationReason:
            'For those who want a fully customizable catering experience.',
    },
]

const menuOptions = {
    'Cocktail Party': [
        'Wine & Beer',
        'Premium Cocktails',
        'Wine & Beer',
        'Non-Alcoholic Beverages',
    ],
}

const CollaboratorModal = ({
    open,
    onClose,
    onAddAddon,
    collaborator,
    type,
}) => {
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [customPackage, setCustomPackage] = useState({
        type: [],
        description: '',
        specificRequests: '',
        menuSelection: '',
    })

    useEffect(() => {
        // Set the recommended package as pre-selected when the modal opens
        const recommendedPackage = cateringPackages.find(
            (pkg) => pkg.recommended
        )
        if (recommendedPackage) {
            setSelectedPackage(recommendedPackage)
        }
    }, [open])

    if (!collaborator) {
        console.error('Modal content is undefined or null')
        return null
    }

    const handlePackageSelect = (pkg) => {
        setSelectedPackage(pkg)
    }

    const handleAddToCart = () => {
        const packageToAdd =
            selectedPackage.id === 5
                ? {
                      ...customPackage,
                      price: null,
                      name: 'Customizable Package',
                  }
                : selectedPackage
        if (packageToAdd) {
            collaborator['collaborator']['price'] = packageToAdd.price
            // collaborator?.customPackage = selectedPackage.id === 5 ? customPackage : null;
            onAddAddon(collaborator.collaborator)
            toast.success(
                `${collaborator?.collaborator?.companyName} added to event`
            ) //- ${packageToAdd.name}
            onClose()
        }
    }

    const handleCustomPackageChange = (field, value) => {
        setCustomPackage((prev) => ({ ...prev, [field]: value }))
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                {/* Usage count section */}
                <Box
                    sx={{
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h5" component="h2">
                        {collaborator.companyName || 'No Title'}
                    </Typography>
                    <Chip
                        icon={<EventIcon />}
                        label={`Used in ${collaborator.count} events`}
                        color="primary"
                        variant="outlined"
                    />
                </Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {collaborator.addonType || 'No Subtitle'}
                </Typography>
                <Typography id="modal-description" sx={{ mb: 3 }}>
                    {collaborator.desc || ''}
                </Typography>

                {/* Recommendations Section */}
                <Box
                    sx={{
                        mt: 4,
                        mb: 2,
                        p: 2,
                        bgcolor: '#f5f5f5',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6">Recommendations</Typography>
                    {cateringPackages.filter((pkg) => pkg.recommended).length >
                    0 ? (
                        cateringPackages
                            .filter((pkg) => pkg.recommended)
                            .map((pkg, index) => (
                                <Box key={index} sx={{ mt: 1 }}>
                                    <Typography variant="subtitle1">
                                        {pkg.name}
                                    </Typography>
                                    <Typography variant="body2">
                                        {pkg.recommendationReason}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Price: ${pkg.price}
                                    </Typography>
                                </Box>
                            ))
                    ) : (
                        <Typography variant="body2">
                            No specific recommendations at this time.
                        </Typography>
                    )}
                </Box>

                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    Select a Catering Package
                </Typography>
                <Box sx={{ overflowX: 'auto', display: 'flex', gap: 2, pb: 2 }}>
                    {cateringPackages.map((pkg) => (
                        <Box key={pkg.id} sx={{ minWidth: 300 }}>
                            <PackageCard
                                package={pkg}
                                isSelected={selectedPackage?.id === pkg.id}
                                onSelect={() => handlePackageSelect(pkg)}
                            />
                        </Box>
                    ))}
                </Box>

                {selectedPackage && selectedPackage.id === 5 && (
                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                multiple
                                value={customPackage.type}
                                onChange={(e) =>
                                    handleCustomPackageChange(
                                        'type',
                                        e.target.value
                                    )
                                }
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {[
                                    'Cocktail Party',
                                    'Tastings Dinatoire',
                                    'Bite Size',
                                    'Lunch & Dinner',
                                    'Food Bar & Verrines',
                                    'Galas',
                                    'In Store Events',
                                    'Breakfast & Brunch',
                                ].map((type) => (
                                    <MenuItem key={type} value={type}>
                                        <Checkbox
                                            checked={
                                                customPackage.type.indexOf(
                                                    type
                                                ) > -1
                                            }
                                        />
                                        <ListItemText primary={type} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Description"
                            multiline
                            rows={2}
                            value={customPackage.description}
                            onChange={(e) =>
                                handleCustomPackageChange(
                                    'description',
                                    e.target.value
                                )
                            }
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Specific Requests"
                            multiline
                            rows={2}
                            value={customPackage.specificRequests}
                            onChange={(e) =>
                                handleCustomPackageChange(
                                    'specificRequests',
                                    e.target.value
                                )
                            }
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Menu Selection</InputLabel>
                            <Select
                                value={customPackage.menuSelection}
                                onChange={(e) =>
                                    handleCustomPackageChange(
                                        'menuSelection',
                                        e.target.value
                                    )
                                }
                            >
                                {menuOptions['Cocktail Party'].map((menu) => (
                                    <MenuItem key={menu} value={menu}>
                                        {menu}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {selectedPackage && selectedPackage.id !== 5 && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">
                            Selected Package: {selectedPackage.name}
                        </Typography>
                        <Typography>Price: ${selectedPackage.price}</Typography>
                    </Box>
                )}

                {selectedPackage && selectedPackage.id === 5 && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">
                            Selected Package: {selectedPackage.name}
                        </Typography>
                        <Typography>
                            Price:{' '}
                            <span style={{ color: 'green' }}>Pending</span>
                        </Typography>
                    </Box>
                )}

                <Box
                    sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button onClick={onClose} sx={{ mr: 2 }}>
                        Close
                    </Button>
                    <Button
                        onClick={handleAddToCart}
                        disabled={!selectedPackage}
                        variant="contained"
                    >
                        Add to Cart
                    </Button>
                </Box>
                <ToastContainer />
            </Box>
        </Modal>
    )
}

export default CollaboratorModal
