import React from 'react'
import { useLocation } from 'react-router-dom'
import EventHostNavBar from './EventHostNavBar'
import VenueOwnerNavBar from './VenueOwnerNavBar'

function ConditionalNavBar({ cartCount }) {
    const location = useLocation()
    console.log(location.pathname)

    // If we are in a path which is created for the host, return the host's navbar
    // If we are in a path which is created for the service provider return the provider's nav bar
    // This needs to be switched to a switch statement that returns the correct navbar based on the kind of service provider
    if (
        location.pathname === '/' ||
        location.pathname === '/contact' ||
        location.pathname === '/pricing'
    ) {
        return <></>
    } else if (location.pathname.includes('venueOwner')) {
        return <VenueOwnerNavBar />
    } else {
        return <EventHostNavBar cartCount={cartCount} />
    }
}

export default ConditionalNavBar
