import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Grid,
    Divider,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Alert,
    Radio,
} from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import { UserContext } from '../contexts/UserContext'
import { getBackendUserUrl } from '../utils'

const SidebarButton = styled(Button)(({ theme, active }) => {
    console.log('>> Theme:', theme);
    console.log('>> Active:', active);

    return {
        justifyContent: 'flex-start',
        padding: theme.spacing(1, 2),
        textAlign: 'left',
        backgroundColor: active ? theme.palette.mode : 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.mode,
        },
    };
})

const UserProfilePage = () => {
    const { user, setUser } = useContext(UserContext)
    const [activeSection, setActiveSection] = useState('Account')
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        preferredName: '',
        companyAffiliation: '',
        email: '',
        address: '',
        phone: '',
        type: [], // Initialize as an empty array
    })
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    useEffect(() => {
        if (user) {
            setUserInfo({
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                preferredName: user?.preferredName || '',
                companyAffiliation: user?.companyAffiliation || '',
                email: user?.email || '',
                address: user?.address || '',
                phone: user?.phone || '',
                type: Array.isArray(user?.type)
                    ? user.type
                    : [user?.type].filter(Boolean), // Ensure it's always an array
            })
        }
        console.log('userInfo here', userInfo)
        console.log('user here', user)
    }, [user])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleTypeChange = (event) => {
        const { value, checked } = event.target
        setUserInfo((prevState) => ({
            ...prevState,
            type: checked
                ? [...new Set([...prevState.type, value])] // Add value and remove duplicates
                : prevState.type.filter((type) => type !== value), // Remove value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        setSuccess('')
        try {
            const token = localStorage.getItem('authToken')

            // Only include fields that have a value
            const updateData = Object.fromEntries(
                Object.entries(userInfo).filter(([_, v]) => {
                    if (Array.isArray(v)) return v.length > 0
                    return v != null && v !== ''
                })
            )

            // Ensure type is an array of strings
            if (updateData.type) {
                updateData.type = Array.from(new Set(updateData.type))
            }

            const apiUrl = getBackendUserUrl() + '/user/update'
            const response = await axios.put(apiUrl, updateData, {
                headers: { Authorization: `Bearer ${token}` },
            })

            if (response.status === 200) {
                const updatedUser = response.data
                setUser(updatedUser)
                localStorage.setItem('userData', JSON.stringify(updatedUser))
                setSuccess('Profile updated successfully!')
            } else {
                throw new Error('Failed to update profile')
            }
        } catch (error) {
            console.error('Error updating profile:', error)
            setError(error.response?.data?.detail || error.message)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                bgcolor: 'background.default',
            }}
        >
            {/* Sidebar */}
            <Paper
                elevation={3}
                sx={{ width: 200, p: 2, display: { xs: 'none', sm: 'block' } }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    User Profile
                </Typography>
                <SidebarButton
                    fullWidth
                    active={activeSection === 'Account'}
                    onClick={() => setActiveSection('Account')}
                >
                    Account
                </SidebarButton>
                {/* <SidebarButton
          fullWidth
          active={activeSection === 'Settings'}
          onClick={() => setActiveSection('Settings')}
        >
          Settings
        </SidebarButton>
        <SidebarButton
          fullWidth
          active={activeSection === 'Contact Us'}
          onClick={() => setActiveSection('Contact Us')}
        >
          Contact Us
        </SidebarButton>
        <SidebarButton
          fullWidth
          active={activeSection === 'Privacy'}
          onClick={() => setActiveSection('Privacy')}
        >
          Privacy
        </SidebarButton> */}
            </Paper>

            {/* Main content */}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Account Information
                </Typography>
                <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    value={userInfo.firstName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    value={userInfo.lastName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Preferred Name"
                                    name="preferredName"
                                    value={userInfo.preferredName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Company Affiliation"
                                    name="companyAffiliation"
                                    value={userInfo.companyAffiliation}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={userInfo.email}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    value={userInfo.phone}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    value={userInfo.address}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    User Type
                                </Typography>
                                <FormGroup>
                                    {['Host', 'Vendor', 'Sponsor'].map(
                                        (type) => (
                                            <FormControlLabel
                                                key={type}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            Array.isArray(
                                                                userInfo?.type
                                                            ) &&
                                                            userInfo.type.includes(
                                                                type
                                                            )
                                                        }
                                                        onChange={
                                                            handleTypeChange
                                                        }
                                                        value={type}
                                                        name="type"
                                                    />
                                                }
                                                label={type}
                                            />
                                        )
                                    )}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Box>
        </Box>
    )
}

export default UserProfilePage
