import React, { useState } from 'react'
import './Card.css'
import Button from '@mui/material/Button'
import PackageInfoModal from './PackageInfoModal'

const Card = ({ companyCard, addonType, desc, count, onQuickAdd }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedPackageOptions, setSelectedPackageOptions] = useState([])

    const handleOpenModal = () => {
        // If the selected vendor has package options then show modal so users can choose.
        // If the selected vendor doesn't have package options, just add it to the card right away
        try {
            const options = companyCard.company.packageInfo.options
            if (options) {
                setSelectedPackageOptions(options)
                setIsModalOpen(true)
            } 
        } catch (error) {
            onQuickAdd(companyCard)
        }
    }

    return (
        <div className="card">
            <div className="textBlock">{companyCard.companyName}</div>
            <div className="textBlock2">{addonType}</div>
            <div className="description">Worked with {count} times</div>
            <div>
                <Button
                    onClick={(e) => {
                        handleOpenModal()
                        e.stopPropagation()
                    }}
                >
                    Quick Add
                </Button>
            </div>

            <PackageInfoModal 
                open={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                packageOptions={selectedPackageOptions} 
                onSelectPackage={(option) => {
                    console.log('Selected package:', option)
                    onQuickAdd(companyCard, option.id)
                    setIsModalOpen(false)
                }} 
            />
        </div>
    )
}

export default Card
