// MessageSidebar.jsx
import React from 'react'
import {
    List,
    ListItemButton,
    ListItemText,
    Typography,
    Divider,
    Box,
    AvatarGroup,
} from '@mui/material'
import { events } from '../../data/conversations'
import ProfileIcon from './ProfileIcon'

function MessageSidebar({ onSelectConversation, selectedConversation }) {
    return (
        <List sx={{ overflowY: 'auto', height: '100%' }}>
            {events.map((event) => (
                <React.Fragment key={event.id}>
                    <Typography variant="h6" sx={{ pl: 2, pt: 1 }}>
                        {event.eventName}
                    </Typography>
                    {event.conversations.map((conv) => (
                        <React.Fragment key={conv.id}>
                            <ListItemButton
                                selected={selectedConversation?.id === conv.id}
                                onClick={() => onSelectConversation(conv)}
                            >
                                <Box sx={{ mr: 2 }}>
                                    {conv.participants &&
                                    conv.participants.length > 1 ? (
                                        <AvatarGroup
                                            max={3}
                                            sx={{ width: 30, height: 30 }}
                                        >
                                            {conv.participants.map(
                                                (participant, index) => (
                                                    <ProfileIcon
                                                        key={index}
                                                        user={participant}
                                                        size="small"
                                                    />
                                                )
                                            )}
                                        </AvatarGroup>
                                    ) : (
                                        <ProfileIcon
                                            user={conv.name}
                                            size="small"
                                        />
                                    )}
                                </Box>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: conv.unread
                                                    ? 'bold'
                                                    : 'regular',
                                            }}
                                        >
                                            {conv.name}
                                        </Typography>
                                    }
                                    secondary={conv.lastMessage}
                                />
                                <Typography variant="caption">
                                    {new Date(
                                        conv.timestamp
                                    ).toLocaleDateString()}
                                </Typography>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </List>
    )
}

export default MessageSidebar
