import React from 'react';
import { Grid, Button, Box } from '@mui/material';
import VenueBox from '../../components/Venue/VenueBox';

const venues = [
    {
        name: 'Lume Studios, NYC',
        location: 'Lume Studios, NYC',
        capacity: 500,
        description:
            'LUME Studios is an immersive venue and experiential creative studio. Through the use of high-end visual software, projection mapping, and spatial audio, we create mind-blowing environments that activate unforgettable experiences for our clients. ',
        imageUrl:
            'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/db85687b-8049-40e4-a6f1-0ab3715afbf5/82f89f6d-b20a-47fd-8e9c-436558be8e80_LumeStudiosX.jpg',
        link: '/venue/db85687b-8049-40e4-a6f1-0ab3715afbf5',
    },
];

const MyVenues = () => {
    return (
        <Box sx={{ padding: 4, position: 'relative' }}>
            <Button 
                variant="contained" 
                color="primary" 
                sx={{ position: 'absolute', top: 16, right: 16 }}
                onClick={() => {
                    console.log("Add Venue button clicked");
                }}
            >
                Add Venue
            </Button>

            <Grid container spacing={4}>
                {venues.map((venue, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <VenueBox venueData={venue} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MyVenues;
