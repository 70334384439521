import React, { useState, useEffect } from 'react'
import LoginCard from '../components/LoginCard'
import { motion } from 'framer-motion'
import { ChevronRight, ArrowUpRight, ChevronDown } from 'lucide-react'
import { Redirect, Link } from 'react-router-dom'
import { Store, Users, BarChart2, Network } from 'lucide-react'
import '../styles/landing.css'
import logo from '../assets/happeings_logo.png'

const navItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: (isInitialLoad) => ({
        opacity: 1,
        y: 0,
        transition: {
            duration: isInitialLoad ? 0.5 : 0, // Only animate on initial load
            delay: isInitialLoad ? 0.2 : 0, // Only delay on initial load
        },
    }),
}

const LandingPage = () => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [showLoginCard, setShowLoginCard] = useState(false)
    const [redirectTo, setRedirectTo] = useState(null)
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    const handleNavigation = (path) => {
        setRedirectTo(path)
    }

    useEffect(() => {
        const timer = setTimeout(() => setIsInitialLoad(false), 800)
        return () => clearTimeout(timer)
    }, [])

    const handleLoginSuccess = (userData) => {
        setShowLoginCard(false)
        alert('Thank you for requesting access for beta!')
    }

    const navItems = [
        {
            label: 'Pricing',
            path: '/pricing',
        },
        {
            label: 'Contact',
            path: '/contact',
        },
    ]

    const solutionsItem = {
        label: 'Solutions',
        dropdown: [
            {
                label: 'Marketplace',
                icon: Store,
                path: '/marketplace',
            },
            {
                label: 'Vendor Management',
                icon: Users,
                path: '/myevents',
            },
            {
                label: 'Analytics',
                icon: BarChart2,
                path: '/eventanalytics',
            },
            {
                label: 'Knowledge Graph',
                icon: Network,
                path: '/',
            },
        ],
    }

    // Add redirect handling
    if (redirectTo) {
        return <Redirect to={redirectTo} />
    }

    // Handle hover for dropdowns
    const handleMouseOver = (dropdownId) => {
        setActiveDropdown(dropdownId)
    }

    const handleMouseOut = () => {
        setActiveDropdown(null)
    }

    return (
        <div className="landing-page">
            <nav className="nav">
                <motion.div
                    variants={navItemVariants}
                    initial="hidden"
                    animate="visible"
                    custom={isInitialLoad}
                    className="logo"
                    style={{ marginLeft: '8%' }}
                >
                    <Link to="/" className="navbar-brand">
                        {/* <img 
                            src={logo} 
                            alt="Logo" 
                            style={{ 
                                height: '70px', 
                                width: 'auto',
                                marginRight: '10px'
                            }} 
                        /> */}
                        happenings
                    </Link>
                </motion.div>

                <div className="nav-items">
                    <motion.div
                        key="solutions"
                        variants={navItemVariants}
                        custom={isInitialLoad}
                        initial="hidden"
                        animate="visible"
                        className={`nav-item ${isInitialLoad ? 'animate-on-load' : ''}`}
                        onMouseOver={() => handleMouseOver('solutions')}
                        onMouseOut={handleMouseOut}
                        style={{
                            transition: 'none',
                            transform: 'none',
                        }}
                    >
                        <Link
                            to="#"
                            className="nav-button"
                            style={{
                                transition: 'none',
                                transform: 'none',
                            }}
                        >
                            {solutionsItem.label}
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{
                                    rotateX:
                                        activeDropdown === 'solutions'
                                            ? 180
                                            : 0,
                                }}
                                transition={{ duration: 0.2 }}
                                style={{
                                    marginLeft: '0.5rem',
                                    transformOrigin: 'center',
                                }}
                            >
                                <ChevronDown className="w-3 h-3" />
                            </motion.div>
                        </Link>
                    </motion.div>

                    {navItems.map((item, index) => (
                        <motion.div
                            key={index}
                            variants={navItemVariants}
                            custom={isInitialLoad}
                            initial="hidden"
                            animate="visible"
                            className={`nav-item ${isInitialLoad ? 'animate-on-load' : ''}`}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                            style={{
                                transition: 'none',
                                transform: 'none',
                            }}
                        >
                            <Link
                                to={item.path}
                                className="nav-button"
                                style={{
                                    transition: 'none',
                                    transform: 'none',
                                }}
                            >
                                {item.label}
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </nav>

            <div className="main-content">
                <div className="hero-content">
                    <div>
                        <motion.h1
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                            className="hero-title"
                        >
                            Effortless Event Planning with AI
                        </motion.h1>

                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                            className="hero-description"
                        >
                            All-in-one platform with AI-powered planning, vendor
                            matching, and automated approvals—saving you time
                            and ensuring your event's success.
                        </motion.p>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                        >
                            <button
                                onClick={() => setShowLoginCard(true)}
                                className="cta-button"
                            >
                                Get Early Access{' '}
                                <ChevronRight
                                    style={{ marginLeft: '0.5rem' }}
                                />
                            </button>
                        </motion.div>
                    </div>
                </div>

                <div className="animation-placeholder">
                    <video
                        className="placeholder-box"
                        style={{ 
                            borderRadius: '20px',
                            overflow: 'hidden',
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', // Optional: adds subtle depth
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        src={require('../assets/Happenings_Demo.mp4')}
                        autoPlay
                        muted
                        loop
                        playsInline
                        onLoadStart={(e) => e.target.play()}
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            {showLoginCard && (
                <LoginCard
                    onClose={() => setShowLoginCard(false)}
                    onUserLogin={handleLoginSuccess}
                />
            )}
        </div>
    )
}

export default LandingPage
