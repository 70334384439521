import React from 'react';
import { Box, TextField, Typography, Grid } from '@mui/material';
import CollapsibleSection from 'components/common/CollapsibleSection';

const MarketingSection = ({ 
    eventData, 
    handleChange, 
    isEditing, 
    onEdit, 
    onSave,
    highlightedSection,
    marketingRef 
}) => {
    // Check if required fields are filled
    const isComplete = Boolean(
        eventData.event.marketing_strategy &&
        eventData.event.expected_media_coverage
    );

    return (
        <CollapsibleSection
            title="Marketing and Promotion"
            isComplete={isComplete}
            isEditing={isEditing}
            onEdit={onEdit}
            onSave={onSave}
        >
            <Box
                sx={{
                    transition: 'all 0.3s ease-in-out',
                    ...(highlightedSection === 'marketing' && {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                        borderRadius: '4px',
                        padding: '8px',
                    }),
                }}
            >
                <Typography variant="subtitle1" gutterBottom>
                    Marketing Channels
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Promotional Strategy"
                    value={eventData.event.marketing_strategy || ''}
                    onChange={(e) =>
                        handleChange(
                            'event',
                            'marketing_strategy',
                            e.target.value
                        )
                    }
                    disabled={!isEditing}
                    multiline
                    rows={2}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Expected Media Coverage"
                    value={eventData.event.expected_media_coverage || ''}
                    onChange={(e) =>
                        handleChange(
                            'event',
                            'expected_media_coverage',
                            e.target.value
                        )
                    }
                    disabled={!isEditing}
                    multiline
                    rows={2}
                />
            </Box>
        </CollapsibleSection>
    );
};

export default MarketingSection; 