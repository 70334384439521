import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { format, parseISO } from 'date-fns'

import {
    Tabs,
    Tab,
    Divider,
    Link,
    Box,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Button,
    Paper,
    TextField,
} from '@mui/material'
import MyEventsAnalytics from './MyEventAnalytics'
import EventTimeline from './EventTimeline'
import DocumentUploader from './DocumentUploader'
import EventOverview from './EventOverview'
import TasksTable from './TasksTable'
import ChatBotIcon from './ChatIcon' // Import the new component
import MessagesPage from 'pages/MessagesPage'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PeopleIcon from '@mui/icons-material/People'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import { getBackendUserUrl } from '../../../utils'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration' // Import duration plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration) // Extend dayjs with the duration plugin

const eventDetails1 = {
    basics: {
        title: 'X Ventures Networking and Panel',
        date: 'August 21, 2024',
        time: '17:30 - 21:00',
        duration: '3.5 hours',
        vision: 'Connecting top AI innovators with industry leaders during NYC Tech Week',
        type: 'Networking and Panel Discussion',
    },
    audience: {
        target: 'Founders, VCs, Software Engineers, AI Enthusiasts',
        expectedAttendees: 150,
        demographics: {
            ageRange: '25-45',
            background: 'Tech professionals, Entrepreneurs, Investors',
        },
    },
    venue: {
        name: 'Lume Studios, NYC',
        location: 'Lume Studios, NYC',
        capacity: 500,
        description:
            'LUME Studios is an immersive venue and experiential creative studio. Through the use of high end visual software, projection mapping and spatial audio we create mind-blowing environments that activate unforgettable experiences for our clients. ',
        imageUrl:
            'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/db85687b-8049-40e4-a6f1-0ab3715afbf5/82f89f6d-b20a-47fd-8e9c-436558be8e80_LumeStudiosX.jpg',
        link: '/venue/db85687b-8049-40e4-a6f1-0ab3715afbf5',
    },
    description:
        'X Ventures Networking and Panel is a premier event showcasing top class AI companies in the current cohert as well as hosting a panel of some of the brightest minds in AI.',
    cost: 20000,
    images: [
        'https://your-image-url.com/conference1.jpg',
        'https://your-image-url.com/conference2.jpg',
        'https://your-image-url.com/conference3.jpg',
    ],
    venueFeatures: {
        seating: '',
    },
    addons: [
        { category: 'Venue', name: 'VIP Seating', price: 500, confirmed: true },
        {
            category: 'Catering',
            name: 'Tastings NYC | SoFlo Catering',
            price: 5000,
            confirmed: false,
        },
        {
            category: 'Photography',
            name: 'Premium Pictures',
            price: 1000,
            confirmed: true,
        },
        {
            category: 'Marketing',
            name: 'Instagram Ads',
            price: 1000,
            confirmed: true,
        },
        {
            category: 'Marketing',
            name: 'NYC Tech Influencers',
            price: 2000,
            confirmed: true,
        },
    ],
    sponsors: [
        {
            name: 'TechCorp',
            logoUrl:
                'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/db85687b-8049-40e4-a6f1-0ab3715afbf5/82f89f6d-b20a-47fd-8e9c-436558be8e80_LumeStudiosX.jpg',
            price: 2000,
        },
        {
            name: 'InnovateX',
            logoUrl: 'https://your-image-url.com/innovatex-logo.jpg',
            price: 2000,
        },
    ],
    format: {
        agenda: '17:30 - Networking, 18:30 - Panel Discussion, 20:00 - Happy Hour',
        keyActivities: [
            'Keynote Speech',
            'Panel Discussion',
            'Q&A Session',
            'Networking',
        ],
        specialFeatures: ['VIP Lounge', 'AI Demo Area'],
    },
    speakers: [
        { name: 'Jane Doe', bio: 'CEO of AI Innovations Inc.' },
        { name: 'John Smith', bio: 'Renowned AI Researcher' },
        { name: 'Emily Chen', bio: 'Partner at Tech Ventures Capital' },
    ],
    marketing: {
        channels: ['LinkedIn', 'Twitter', 'Email Newsletter', 'Tech Blogs'],
        strategy: 'Leverage NYC Tech Week buzz, partner with tech influencers',
        expectedMediaCoverage:
            'TechCrunch, Venture Beat, local NYC tech publications',
    },
    financials: {
        venueCost: 20000,
        addons: [
            {
                category: 'Venue',
                name: 'VIP Seating',
                price: 500,
                confirmed: true,
            },
            {
                category: 'Catering',
                name: 'Tastings NYC | SoFlo Catering',
                price: 5000,
                confirmed: false,
            },
            {
                category: 'Photography',
                name: 'Premium Pictures',
                price: 1000,
                confirmed: true,
            },
            {
                category: 'Marketing',
                name: 'Instagram Ads',
                price: 1000,
                confirmed: true,
            },
            {
                category: 'Marketing',
                name: 'NYC Tech Influencers',
                price: 2000,
                confirmed: true,
            },
        ],
    },
}

const EventDetailsPage = () => {
    const { eventId } = useParams()
    const [eventOverviewDetails, setEventOverviewDetails] = useState(null)
    const [error, setError] = useState(null)

    const [currentTab, setCurrentTab] = useState('overview')
    const [eventDetailsTemp, setEventDataTemp] = useState(eventDetails1)
    const [isAdditionalCardOpen, setAdditionalCardOpen] = useState(false)
    const [editableFields, setEditableFields] = useState({
        vision: 'A networking event connecting top industry leaders.',
        audience: 'Founders, VCs, Software Engineers',
        type: 'Corporate Networking, Panel Discussion',
    })

    // const [addons, setAddons] = useState();
    console.log('EventDetailsPage')
    console.log('event', eventOverviewDetails)
    const [timelineEvents, setTimelineEvents] = useState([])
    const [messageTags, setMessageTags] = useState([])
    const [highlightedSection, setHighlightedSection] = useState(null)

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const apiUrl = `${getBackendUserUrl()}/event/event_details/${eventId}`
                const response = await axios.get(apiUrl)
                setEventOverviewDetails(response.data)
                fetchEventTimeline(eventId)
            } catch (error) {
                console.error('Failed to fetch event details:', error)
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Error data:', error.response.data)
                    console.error('Error status:', error.response.status)
                    console.error('Error headers:', error.response.headers)
                    setError(
                        `Failed to load event details: ${error.response.data.detail || error.response.statusText}`
                    )
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Error request:', error.request)
                    setError('No response received from server')
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error message:', error.message)
                    setError(`Error: ${error.message}`)
                }
            }
        }

        if (eventId) {
            fetchEventDetails()
        }
    }, [eventId])

    const fetchEventTimeline = async (eventId) => {
        console.log('fetchEventTimeline', eventId)
        const apiUrl = `${getBackendUserUrl()}/event/event_timeline/${eventId}`
        const response = await axios.get(apiUrl)
        console.log('timelineEvents', response.data)
        setTimelineEvents(response.data)
    }

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const handleAddTag = (tag) => {
        setMessageTags((prevTags) => [...prevTags, tag])
        handleTagNavigation(tag)
    }

    // Calculate the event duration in hours
    const calculateEventDuration = (start, end) => {
        const startDate = dayjs(start)
        const endDate = dayjs(end)
        const eventDuration = dayjs.duration(endDate.diff(startDate))
        return eventDuration.asHours().toFixed(1) // Get the duration in hours with one decimal point
    }

    const renderTabContent = () => {
        switch (currentTab) {
            case 'overview':
                return (
                    <Grid container spacing={4}>
                        <Grid item xs={12} id="overview-section">
                            <EventOverview
                                eventData={eventOverviewDetails}
                                setEventData={setEventOverviewDetails}
                                onAddTag={handleAddTag}
                            />
                        </Grid>
                    </Grid>
                )
            case 'agenda':
                return (
                    <Grid item xs={12}>
                        <Paper
                            elevation={3}
                            sx={{ p: 3 }}
                            id="Event-Timeline-section"
                        >
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                            >
                                Event Timeline
                            </Typography>
                            <EventTimeline
                                eventDetails={eventOverviewDetails}
                                events={timelineEvents}
                                onAddEvent={handleAddTimelineEvent}
                                onEditEvent={handleEditTimelineEvent}
                                onDeleteEvent={handleDeleteTimelineEvent}
                                onSyncGoogleCalendar={handleGoogleCalendarSync}
                                // addons={addons}
                            />
                        </Paper>
                    </Grid>
                )
            case 'notifications':
                return (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <MessagesPage
                                messageTags={messageTags}
                                setMessageTags={setMessageTags}
                            />
                        </Grid>
                    </Grid>
                )
            case 'tasks':
                return (
                    <Grid item xs={12}>
                        <TasksTable eventDetails={eventDetailsTemp} />
                    </Grid>
                )
            case 'eventAnalytics':
                return (
                    <Grid item xs={12}>
                        <MyEventsAnalytics eventDetails={eventDetailsTemp} />
                    </Grid>
                )
        }
    }

    const handleAddTimelineEvent = async (newEvent) => {
        try {
            console.log('Creating new timeline event:', newEvent)
            const apiUrl = `${getBackendUserUrl()}/event/timeline_event`
            const response = await axios.post(apiUrl, newEvent)

            if (response.data) {
                console.log('Timeline event created:', response.data)
                setTimelineEvents((prevEvents) => [
                    ...prevEvents,
                    response.data,
                ])
            }
        } catch (error) {
            console.error('Error adding timeline event:', error)
        }
    }

    const handleEditTimelineEvent = async (editedEvent) => {
        try {
            console.log('Updating timeline event:', editedEvent)
            const response = await axios.put(
                `${getBackendUserUrl()}/event/timeline_event/${editedEvent.eventTimelineId}`,
                editedEvent
            )

            if (response.data) {
                console.log('Timeline event updated:', response.data)
                setTimelineEvents((prevEvents) =>
                    prevEvents.map((event) =>
                        event.eventTimelineId === editedEvent.eventTimelineId
                            ? response.data
                            : event
                    )
                )
            }
        } catch (error) {
            console.error('Error editing timeline event:', error)
        }
    }

    const handleDeleteTimelineEvent = async (eventTimelineId) => {
        try {
            console.log('Deleting timeline event:', eventTimelineId)
            await axios.delete(
                `${getBackendUserUrl()}/event/timeline_event/${eventTimelineId}`
            )

            setTimelineEvents((prevEvents) =>
                prevEvents.filter(
                    (event) => event.eventTimelineId !== eventTimelineId
                )
            )
        } catch (error) {
            console.error('Error deleting timeline event:', error)
        }
    }

    const handleGoogleCalendarSync = async () => {
        try {
            // This is a placeholder for the actual Google Calendar API integration
            // You'll need to implement the actual API calls here
            console.log('Syncing with Google Calendar...')

            // Simulate API call
            await new Promise((resolve) => setTimeout(resolve, 1000))

            console.log('Sync completed')
            // You might want to show a success message to the user here
        } catch (error) {
            console.error('Error syncing with Google Calendar:', error)
            // You might want to show an error message to the user here
        }
    }

    const handleOpenAdditionalCard = () => {
        setAdditionalCardOpen(true)
    }

    const handleCloseAdditionalCard = () => {
        setAdditionalCardOpen(false)
    }

    const handleChange = (field) => (event) => {
        setEditableFields({
            ...editableFields,
            [field]: event.target.value,
        })
    }

    // const totalCost = (eventDetails.cost
    //   + eventDetails.addons.reduce((sum, addon) => sum + addon.price, 0)
    //   - eventDetails.sponsors.reduce((sum, sponsor) => sum + sponsor.price, 0)).toLocaleString();

    const handleDocumentUpload = (parsedData) => {
        setEditableFields((prevFields) => ({
            ...prevFields,
            ...parsedData,
        }))
        // Update other components or state here as well
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId)
        if (section) {
            // Determine which tab contains this section
            let tabValue = 'overview' // default
            if (sectionId === 'Event-Timeline-section') {
                tabValue = 'agenda'
            }
            // Add more conditions for other sections if needed

            // Change to the correct tab
            setCurrentTab(tabValue)

            // Use setTimeout to allow the tab content to render before scrolling
            setTimeout(() => {
                section.scrollIntoView({ behavior: 'smooth' })
            }, 100)
        }
    }

    useEffect(() => {
        // Make the scrollToSection function globally accessible
        window.scrollToSection = scrollToSection

        // Clean up
        return () => {
            delete window.scrollToSection
        }
    }, [])

    const handleTagNavigation = (tagName) => {
        console.log('Navigating to tag:', tagName)

        if (!tagName) {
            console.warn('No tag name provided for navigation')
            return
        }

        let targetTab = 'overview'
        let targetSection = null

        switch (tagName.toString().toLowerCase()) {
            // Overview Tab Sections
            case 'event basics':
            case 'date and time':
            case 'event vision':
            case 'event type':
                targetTab = 'overview'
                targetSection = 'Event-Basics-section'
                break

            case 'target audience':
            case 'audience information':
            case 'demographics':
                targetTab = 'overview'
                targetSection = 'audience-section'
                break

            case 'venue':
            case 'location':
                targetTab = 'overview'
                targetSection = 'venue-section'
                break

            case 'event format':
            case 'agenda overview':
                targetTab = 'overview'
                targetSection = 'format-section'
                break

            case 'speakers':
            case 'speakers and presenters':
                targetTab = 'overview'
                targetSection = 'speakers-section'
                break

            case 'marketing and promotion':
            case 'marketing strategy':
                targetTab = 'overview'
                targetSection = 'marketing-section'
                break

            // Other Tabs
            case 'agenda':
            case 'timeline':
                targetTab = 'agenda'
                targetSection = 'Event-Timeline-section'
                break

            case 'vendors':
                targetTab = 'vendors'
                break

            case 'tasks':
                targetTab = 'tasks'
                break

            default:
                targetTab = 'overview'
                break
        }

        // Switch to the correct tab
        setCurrentTab(targetTab)

        // Scroll to section after tab switch
        setTimeout(() => {
            if (targetSection) {
                const element = document.getElementById(targetSection)
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                    // Add highlight effect
                    element.style.backgroundColor = 'rgba(25, 118, 210, 0.1)'
                    setTimeout(() => {
                        element.style.backgroundColor = ''
                    }, 3000)
                }
            }
        }, 100)
    }

    if (error) return <div>{error}</div>
    if (!eventOverviewDetails) return <div>Loading event details...</div>

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {/* Event Title */}
            <Typography variant="h4" component="h1" gutterBottom>
                {eventOverviewDetails.event.title}
            </Typography>

            {/* Event Details in Styled Boxes */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                }}
            >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Paper
                        elevation={1}
                        sx={{
                            p: 1,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CalendarMonthIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                            {eventOverviewDetails.event.eventStartDate
                                ? format(
                                      parseISO(
                                          eventOverviewDetails.event
                                              .eventStartDate
                                      ),
                                      'MMMM d, yyyy h:mm a'
                                  )
                                : 'Date not set'}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={1}
                        sx={{
                            p: 1,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LocationOnIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                            {eventOverviewDetails.venue.city}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={1}
                        sx={{
                            p: 1,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <PeopleIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                            {eventOverviewDetails.event.guestCount}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={1}
                        sx={{
                            p: 1,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                            {' '}
                            {calculateEventDuration(
                                eventOverviewDetails.event.eventStartDate,
                                eventOverviewDetails.event.eventEndDate
                            )}{' '}
                            hours
                        </Typography>
                        {/* <Typography variant="body2">{eventOverviewDetails.event.eventStartTime}</Typography> */}
                    </Paper>
                </Box>

                <DocumentUploader onUploadComplete={() => {}} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    aria-label="event details tabs"
                    sx={{ mb: 4 }}
                >
                    <Tab label="Overview" value="overview" />
                    <Tab label="Agenda" value="agenda" />
                    {/* <Tab label="Notifications" value="notifications" /> */}
                    {/* <Tab label="Vendors" value="vendors" /> */}
                    {/* <Tab label="Tasks" value="tasks" /> */}
                    {/* <Tab label="Sponsor Deck" value="sponsorDeck" /> */}
                    {/* <Tab label="Event Analytics" value="eventAnalytics" /> */}
                </Tabs>
            </Box>

            {renderTabContent()}

            <ChatBotIcon />
        </Container>
    )
}

export default EventDetailsPage
